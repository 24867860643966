import React, { useMemo } from "react";
import { Table } from "antd";
import { Link, useHistory } from "react-router-dom";
import S from "../../styles/handbooks";
import { t } from "../../utils";
import { BreadCrumbs } from "../../components";
import handbookNames from "../../constants/handbookNames";
import { useSelector } from "react-redux";

export default function Handbooks() {
  const myAccount = useSelector((state) => state.auth.user || {});
  const data = useMemo(
    () => Object.values(handbookNames).filter((item) => !item.static),
    []
  );
  const history = useHistory();

  const handleClick = () => {
    history.push("streets");
  };

  const columns = useMemo(
    () => [
      {
        key: "url",
        title: "#",
        dataIndex: "url",
        width: 30,
        render: (val, record, index) => {
          return index + 1;
        },
      },
      {
        key: "name",
        title: t("Ma'lumotnoma nomi"),
        dataIndex: "name",
        width: "100%",
        render: (val, record, index) => {
          return (
            <Link
              to={`/handbooks/${record.nested ? record.name : record.url}`}
              className="d-flex w-100 clickable"
            >
              {record[myAccount.lang]}
            </Link>
          );
        },
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b) => {
          if (
            a[myAccount.lang]?.toLowerCase() > b[myAccount.lang]?.toLowerCase()
          ) {
            return 1;
          } else if (
            a[myAccount.lang]?.toLowerCase() < b[myAccount.lang]?.toLowerCase()
          ) {
            return -1;
          } else {
            return 0;
          }
        },
        defaultSortOrder: "ascend",
      },
    ],
    [myAccount.lang]
  );

  return (
    <>
      <BreadCrumbs
        extra={[
          {
            url: "/handbooks",
            title: t("Ma'lumotnomalar"),
          },
        ]}
      />
      <S.List className="b-1 b-radius">
        <div className="d-flex mb-4 p-3 bb-1">
          <h2 className="mb-0">{t("Ma'lumotnomalar")}</h2>
        </div>
        <Table
          dataSource={data}
          columns={columns}
          className="card-list px-4"
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) =>
                record.nested
                  ? history.push(`/nested-handbooks/${record.name}/`)
                  : history.push(`/handbooks/${record.url}`), // click row
            };
          }}
          rowKey="name"
          scroll={{ x: true }}
          pagination={false}
        />

        <div
          style={{
            height: "50px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "40px",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          28{" "}
          <span
            style={{
              color: "#42c293",
              paddingLeft: "29px",
            }}
          >
            Ko'chalar
          </span>
        </div>
      </S.List>
    </>
  );
}
