/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  Button,
  Tag,
  message,
  Space,
  Modal,
  Input,
  Row,
  Col,
  Switch,
  Select,
  Image,
} from "antd";
import { useParams, useHistory } from "react-router-dom";
import { handbookAxios, cancelToken, axios } from "../../utils/axios";
import S from "../../styles/handbooks";
import { BiEditAlt, BiPlusCircle, BiTrash, BiCheck, BiX } from "react-icons/bi";
import { t, getHandbookName, isAdmin, isReadOnlyUser } from "../../utils";
import colors from "../../styles/colors";
import { FormSelect, BreadCrumbs } from "../../components";
import { UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { Upload } from "antd";
import handBookNames from "../../constants/handbookNames";
import { useSelector, useDispatch } from "react-redux";
import handbookActions from "../../store/handbooks/actions";
import HandbookSearch from "../../components/HandbookSearch";
import { render } from "@testing-library/react";

export default function Handbooks() {
  const source = cancelToken();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [addModalvisible, setAddModalvisible] = useState(false);
  const { accident_types } = useSelector((state) => state.handbooks);
  const [extraFieldData, setExtraFieldData] = useState([]);
  const [inputValues, setInputValues] = useState({
    name: "",
    name_uz: "",
    id: "",
    name_ru: "",
    name_oz: "",
  });
  const [newHandbookValues, setNewHandbookValues] = useState({
    name: "",
    name_uz: "",
    id: "",
    name_ru: "",
    name_oz: "",
  });
  const [pagination, setPagination] = useState({ page: 1, size: 10, count: 0 });
  const [selectedKeys, setSelectedKeys] = useState([]);
  const params = useParams();
  const history = useHistory();
  const currentHandBook =
    handBookNames[params.name === "geo" ? params.id : params.name];
  const currentHandBookId =
    params.name === "geo" ? `geo/${params.id}` : params.name;
  const extraFieldName = params.name === "geo" ? params.id : params.name;
  const myAccount = useSelector((state) => state.auth.user);
  const defaultPage =
    new URLSearchParams(window.location.search).get("page") || 1;
  const defaultSize =
    new URLSearchParams(window.location.search).get("page_size") || 10;
  const [activeSort, setActiveSort] = useState({
    orderDirection: "",
    page: defaultPage,
    page_size: defaultSize,
  });

  useEffect(() => {
    fetchData();
    return () => source.cancel("Component unmounted");
  }, []);

  useEffect(() => {
    handlePage();
    return () => source.cancel("Component unmounted");
  }, [activeSort]);

  useEffect(() => {
    if (inputValues?.image?.id) {
      setInputValues({ ...inputValues, image: inputValues.image?.id });
    }
  }, [inputValues]);

  const props = {
    onChange({ file, fileList }) {
      const formData = new FormData();
      formData.append("file", file.originFileObj);
      axios
        .post("/files/", formData)
        .then((res) => handleNewHandbookInput("image", res.data.id));
    },
  };

  const editProps = {
    onChange({ file, fileList }) {
      const formData = new FormData();
      formData.append("file", file.originFileObj);
      axios
        .post("/files/", formData)
        .then((res) => setInputValues({ ...inputValues, image: res.data.id }));
    },
  };

  useEffect(() => {
    if (currentHandBook) {
      (async () => {
        try {
          const url = currentHandBook.extraFields.reduce((acc, item) => {
            if (item.static || !item.url) {
              return acc;
            }
            acc.push(
              handbookAxios(`/${item.url}/?page_size=10000`, {
                cancelToken: source.token,
              })
            );
            return acc;
          }, []);
          const data = await axios.all(url);
          const staticValues = currentHandBook.extraFields
            .filter((item) => item.static)
            .map((item) => item.values);
          setExtraFieldData([
            ...data.map((item) => item.data.results),
            ...staticValues,
          ]);
        } catch (err) {
          console.log(err);
        }
      })();

      return () => source.cancel("Component unmounted");
    }
  }, []);

  const fetchData = useCallback(() => {
    handlePage();
  }, []);

  const handlePage = useCallback(async () => {
    const { page, page_size, orderDirection } = activeSort;
    let query = "";

    for (let x in activeSort) {
      if (
        (activeSort[x] !== "") & (activeSort[x] !== null) &&
        x !== "orderDirection"
      ) {
        query += `${x}=${activeSort[x]}&`;
      }
    }
    try {
      setLoading(true);
      const { data } = await handbookAxios.get(
        `/${currentHandBookId}/?${query}`,
        { cancelToken: source.token }
      );
      setData(data.results);
      setPagination({
        page: parseInt(page),
        size: parseInt(page_size),
        total: data.count,
      });
      history.push(
        `/handbooks/${currentHandBookId}/?page=${page}&page_size=${page_size}`
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [activeSort]);

  const handleInput = (name, value) => {
    setInputValues((state) => ({ ...state, [name]: value }));
  };

  const handleNewHandbookInput = (name, value) => {
    setNewHandbookValues((state) => ({ ...state, [name]: value }));
  };

  const handleSelect = (name, value) => {
    setInputValues((state) => ({ ...state, [name]: value }));
  };

  const handleNewHandbookSelect = (name, value) => {
    setNewHandbookValues((state) => ({ ...state, [name]: value }));
    if (name === "accident_type") {
      setInputValues({ ...inputValues, accident_type: value });
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: t("O'chirmoqchimisiz?"),
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: "#E7E9EB",
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: "swal-danger",
    }).then(async ({ value }) => {
      if (value) {
        try {
          const res = await handbookAxios.delete(
            `/${currentHandBookId}/${id}/`,
            { cancelToken: source.token }
          );
          fetchData();
          return message.success(t("Ma'lumot muvaffaqiyatli o'chirildi"));
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  const handleSelectedKeysDeletion = async () => {
    Swal.fire({
      title: t("O'chirmoqchimisiz?"),
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: "#E7E9EB",
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: "swal-danger",
    }).then(async ({ value }) => {
      if (value) {
        setLoading(true);
        const requests = selectedKeys.map((item) => {
          return handbookAxios.delete(`/${currentHandBookId}/${item}/`, {
            cancelToken: source.token,
          });
        });

        try {
          const res = await axios.all(requests);
          fetchData();
          setLoading(false);
          setSelectedKeys([]);
          return message.success(t("Ma'lumot muvaffaqiyatli o'chirildi"));
        } catch (err) {
          console.log(err.message);
          setLoading(false);
          message.error(t("Xatolik yuz berdi"));
        }
      }
    });
  };

  const handleModal = async (status = false, id) => {
    setVisible(status);
    if (id) {
      const { data } = await handbookAxios.get(`/${currentHandBookId}/${id}/`, {
        cancelToken: source.token,
      });
      setInputValues(data);
      setChecked(!data.is_deleted);
    }
  };

  const handleSave = useCallback(async () => {
    let { id, ...updatedValue } = inputValues;
    if (!updatedValue.name) {
      updatedValue = {
        ...updatedValue,
        name: updatedValue.name_uz,
        is_deleted: !checked,
      };
    }
    if (id) {
      const { data, error } = await handbookAxios.put(
        `/${currentHandBookId}/${id}/`,
        { ...updatedValue, is_deleted: !checked },
        { cancelToken: source.token }
      );
      if (error) {
        message.error(t("Hatolik yuz berdi"));
      } else {
        message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
        handlePage();
        setVisible(false);
      }
      setInputValues(data);
    }
  }, [inputValues]);

  const handleNewHandbookSave = useCallback(async () => {
    let { id, ...updatedValue } = newHandbookValues;
    if (!updatedValue.name) {
      updatedValue = { ...updatedValue, name: updatedValue.name_uz };
    }

    const { data, error } = await handbookAxios.post(
      `/${currentHandBookId}/`,
      updatedValue,
      { cancelToken: source.token }
    );

    if (error) {
      message.error(t("Hatolik yuz berdi"));
    } else {
      message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
      setNewHandbookValues({});
      fetchData();
    }
    setAddModalvisible(false);
  }, [newHandbookValues, currentHandBookId]);

  const columns = [
    {
      key: "id",
      title: "#",
      dataIndex: "id",
      width: 30,
      render: (val, record, index) => {
        const { page, size } = pagination;
        const number = page > 1 ? (page - 1) * size + index + 1 : index + 1;
        return number;
      },
    },
    {
      key: "name_uz",
      title: t("O'zbekcha"),
      dataIndex: "name_uz",
      render: (val, record, index) => {
        return (
          <div className="d-flex w-100 clickable">
            {val?.replace(/_/g, " ")}
          </div>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b, order) => {
        console.log(order);
      },
    },
    {
      key: "name_oz",
      title: t("Узбекча"),
      dataIndex: "name_oz",
      render: (val, record, index) => {
        return (
          <div className="d-flex w-100 clickable">
            {val?.replace(/_/g, " ")}
          </div>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b, order) => {
        console.log(order);
      },
    },
    {
      key: "name_ru",
      title: t("Ruscha"),
      dataIndex: "name_ru",
      render: (val, record, index) => {
        return (
          <div className="d-flex w-100 clickable">
            {val?.replace(/_/g, " ")}
          </div>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b, order) => {
        console.log(order);
      },
    },
  ];

  const getExtraFieldsByIds = useMemo(() => {
    let data = {};
    extraFieldData.forEach((item, index) => {
      const items = item.reduce((acc, item) => {
        return { ...acc, [item.id]: item };
      }, {});
      const propertyOfExtraField = currentHandBook.extraFields[index].name;
      data[propertyOfExtraField] = items;
    });

    return data;
  }, [extraFieldData, currentHandBook]);

  const extraFieldColumns = handBookNames[extraFieldName]?.extraFields?.map(
    (item) => ({
      key: item.name,
      title: t(item.title["name_" + myAccount.lang]),
      dataIndex: item.name,
      render: (val, record, index) => {
        const field = getExtraFieldsByIds[`${item.name}`];
        const value = record[item.name];
        const name = field ? field[value] : {};

        return (
          <div
            onClick={() => handleModal(true, record.id)}
            className="d-flex w-100 clickable"
            style={{ color: item.name === "code" ? value : "initial" }}
          >
            {name
              ? name[`name_${myAccount.lang}`]
                ? name[`name_${myAccount.lang}`]
                : value
              : ""}
          </div>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b, order) => {},
    })
  );

  const statusColumns = [
    {
      key: "is_deleted",
      title: t("Holati"),
      dataIndex: "is_deleted",
      render: (val, record, index) => (
        <Tag className={`status-${record.is_deleted ? "deleted" : "active"}`}>
          {record.is_deleted ? t("Nofaol") : t("Faol")}
        </Tag>
      ),
      sortDirections: ["ascend", "descend", "ascend"],
      width: 150,
      sorter: (a, b, order) => {},
      filters: [
        {
          text: t("Faol"),
          value: false,
        },
        {
          text: t("Nofaol"),
          value: true,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.is_deleted === value,
    },
  ];

  const imageColumns = [
    {
      key: "image",
      title: "Rasmi",
      data_index: "image",
      render: (val) => {
        return (
          <Image
            onClick={(e) => e.stopPropagation()}
            width="50px"
            src={val?.image?.file}
          />
        );
      },
    },
  ];

  const actionColumn = useMemo(
    () =>
      isAdmin()
        ? [
            {
              key: "actions",
              title: t("Vazifalar"),
              dataIndex: "actions",
              className: "text-center",
              width: 150,
              render: (val, record) => (
                <Space>
                  <Button
                    size="small"
                    onClick={() => handleModal(true, record.id)}
                    type="dashed"
                    className="edit"
                  >
                    <BiEditAlt />{" "}
                  </Button>
                  <Button
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(record.id);
                    }}
                    danger
                    className="delete"
                  >
                    <BiTrash />{" "}
                  </Button>
                </Space>
              ),
            },
          ]
        : [],
    [myAccount.is_superuser]
  );

  const handleSearch = async () => {
    try {
      const { data } = await handbookAxios.get(
        `/${currentHandBookId}/?search=${query}`,
        { cancelToken: source.token }
      );
      setData(data.results);
      setPagination({ page: 1, size: data.count, count: data.count });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClear = () => {
    setQuery("");
    handlePage();
  };

  return (
    <>
      <BreadCrumbs
        extra={[
          {
            url: "/handbooks",
            title: t("Ma'lumotnomalar"),
          },
          {
            url: "#",
            title: getHandbookName(extraFieldName),
          },
        ]}
      />

      <S.List className="b-1 b-radius">
        <div className="d-flex mb-4 p-3 bb-1">
          <h2 className="mb-0">{getHandbookName(extraFieldName)}</h2>
          {isAdmin() && (
            <Button
              className="pl-3 ml-auto"
              type="primary"
              onClick={() => setAddModalvisible(true)}
            >
              {t("Ma'lumot qo'shish")}
              {<BiPlusCircle className="has-icon" />}
            </Button>
          )}
        </div>
        {selectedKeys.length && isAdmin() ? (
          <div className="text-left px-4 mb-2">
            <div>
              <small>
                {t("Tanlangan ma'lumotlar soni:")} {selectedKeys.length}
              </small>
            </div>
            <Button
              icon={<BiTrash />}
              style={{ height: 30 }}
              danger
              size="small"
              onClick={handleSelectedKeysDeletion}
            >
              {t("O'chirish")}
            </Button>
          </div>
        ) : null}
        <div className="mb-4 mx-4">
          <HandbookSearch
            query={query}
            queryHandler={setQuery}
            searchHandler={handleSearch}
            clearHandler={handleClear}
          />
        </div>

        <Table
          rowSelection={
            isAdmin()
              ? {
                  type: "checkbox",
                  selectedRowKeys: selectedKeys,
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedKeys(selectedRowKeys);
                  },
                }
              : null
          }
          dataSource={data}
          columns={[
            ...columns,
            ...extraFieldColumns,
            ...(currentHandBook.name === "sub_accident_types"
              ? imageColumns
              : []),
            ...statusColumns,
            ...actionColumn,
          ]}
          loading={loading}
          className="card-list px-4"
          rowKey="id"
          scroll={{ x: true }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!isReadOnlyUser()) {
                  currentHandBook.hasNestedChild
                    ? history.push(
                        `${currentHandBook.nestedChildUrl}/${record.id}/`
                      )
                    : handleModal(true, record.id); // click row
                }
              },
            };
          }}
          onChange={(sort, filter, column) => {
            let filters = {};
            for (let x in filter) {
              if (filter[x] !== null) {
                filters = { ...filters, [x]: filter[x][0] };
              } else {
                delete filters[x];
              }
            }
            setActiveSort((state) => ({
              page: state.page,
              page_size: state.page_size,

              orderDirection: column.order,
              is_deleted: false,
              ...filters,
            }));
          }}
          pagination={{
            // hideOnSinglePage: true,
            pageSizeOptions: [10, 20, 30],
            showSizeChanger: true,
            current: pagination.page,
            pageSize: pagination.size,
            total: pagination.total,
            onChange: (page, page_size) =>
              setActiveSort((state) => ({
                ...state,
                is_deleted: false,
                page,
                page_size,
              })),
            showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
          }}
        />

        <Modal
          title={t("Tahrirlash")}
          visible={visible}
          width={600}
          onCancel={() => handleModal(false)}
          onOk={() => handleModal(false)}
          footer={null}
          zIndex={1080}
        >
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <label htmlFor="" className="mb-2">
                {t("O'zbekcha")}
              </label>
              <Input.TextArea
                value={inputValues.name_uz}
                className="input-controller"
                onChange={(event) => handleInput("name_uz", event.target.value)}
                placeholder={t("O'zbekcha")}
                autoSize
              />
            </Col>
            <Col span={24}>
              <label htmlFor="" className="mb-2">
                {t("Узбекча")}
              </label>
              <Input.TextArea
                value={inputValues.name_oz}
                className="input-controller"
                onChange={(event) => handleInput("name_oz", event.target.value)}
                placeholder={t("Узбекча")}
                autoSize
              />
            </Col>

            <Col span={24}>
              <label htmlFor="" className="mb-2">
                {t("Ruscha")}
              </label>
              <Input.TextArea
                value={inputValues.name_ru}
                className="input-controller"
                onChange={(event) => handleInput("name_ru", event.target.value)}
                placeholder={t("Ruscha")}
                autoSize
              />
            </Col>
            {currentHandBook.name === "sub_accident_types" && (
              <>
                <Col span={24}>
                  <label htmlFor="" className="mb-2">
                    Ota YTH turini tanlang
                  </label>
                  <FormSelect
                    height={60}
                    size="large"
                    name="accident_type"
                    // onChange={(event) =>
                    //   handleNewHandbookInput("accident_type", event.target.value)
                    // }
                    onChange={handleNewHandbookSelect}
                    value={inputValues.accident_type}
                    placeholder="YTH turini tanlang"
                    options={accident_types}
                  />
                </Col>
                <Col span={24}>
                  <Upload
                    {...editProps}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Button
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      icon={<UploadOutlined />}
                    >
                      Rasm Yuklash
                    </Button>
                  </Upload>
                </Col>
              </>
            )}
            {currentHandBook.extraFields.length
              ? currentHandBook.extraFields.map((item, index) => {
                  return (
                    <Col span={24} key={item.id}>
                      <label
                        htmlFor=""
                        className="mb-2"
                        style={{
                          color:
                            item.name === "code"
                              ? inputValues[item.name]
                              : "initial",
                        }}
                      >
                        {item.title["name_" + myAccount.lang]}
                      </label>
                      {item.type ? (
                        <Input
                          type={item.type}
                          value={inputValues[item.name]}
                          className="input-controller"
                          onChange={(event) =>
                            handleInput(item.name, event.target.value)
                          }
                        />
                      ) : (
                        <FormSelect
                          height={60}
                          size="large"
                          name={item.name}
                          onChange={handleSelect}
                          placeholder={`${
                            item.title["name_" + myAccount.lang]
                          } tanlang`}
                          options={
                            item.static ? item.values : extraFieldData[index]
                          }
                          zIndex={1}
                          value={inputValues[item.name]}
                        />
                      )}
                    </Col>
                  );
                })
              : null}
          </Row>
          <div className="my-3">
            <label htmlFor="is_deleted" className="mr-2">
              {t("Holati")}
            </label>
            <Switch
              checkedChildren={<BiCheck size={20} />}
              unCheckedChildren={<BiX size={20} />}
              checked={checked}
              name="is_deleted"
              onChange={(checked) => {
                setChecked(checked);
                setInputValues((state) => ({ ...state, is_deleted: checked }));
              }}
            />
          </div>

          <div className="text-center">
            <Button
              type="primary"
              onClick={handleSave}
              size="large"
              className="flex-centered mx-auto"
            >
              {t("Saqlash")}
            </Button>
          </div>
        </Modal>

        <Modal
          title={t("Ma'lumot qo'shish")}
          visible={addModalvisible}
          width={600}
          onCancel={() => setAddModalvisible(false)}
          onOk={() => setAddModalvisible(false)}
          footer={null}
          zIndex={1080}
        >
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <label htmlFor="" className="mb-2">
                {t("O'zbekcha")}
              </label>
              <Input.TextArea
                value={newHandbookValues.name_uz}
                className="input-controller"
                onChange={(event) =>
                  handleNewHandbookInput("name_uz", event.target.value)
                }
                placeholder={t("O'zbekcha")}
                autoSize
              />
            </Col>
            <Col span={24}>
              <label htmlFor="" className="mb-2">
                {t("Узбекча")}
              </label>
              <Input.TextArea
                value={newHandbookValues.name_oz}
                className="input-controller"
                onChange={(event) =>
                  handleNewHandbookInput("name_oz", event.target.value)
                }
                placeholder={t("Узбекча")}
                autoSize
              />
            </Col>

            <Col span={24}>
              <label htmlFor="" className="mb-2">
                {t("Ruscha")}
              </label>
              <Input.TextArea
                value={newHandbookValues.name_ru}
                className="input-controller"
                onChange={(event) =>
                  handleNewHandbookInput("name_ru", event.target.value)
                }
                placeholder={t("Ruscha")}
                autoSize
              />
            </Col>

            {currentHandBook.name === "sub_accident_types" && (
              <>
                <Col span={24}>
                  <label htmlFor="" className="mb-2">
                    Ota YTH turini tanlang
                  </label>
                  <FormSelect
                    height={60}
                    size="large"
                    name="accident_type"
                    // onChange={(event) =>
                    //   handleNewHandbookInput("accident_type", event.target.value)
                    // }
                    onChange={handleNewHandbookSelect}
                    placeholder="YTH turini tanlang"
                    options={accident_types}
                  />
                </Col>
                <Col span={24}>
                  <Upload
                    {...props}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Button
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      icon={<UploadOutlined />}
                    >
                      Rasm Yuklash
                    </Button>
                  </Upload>
                </Col>
              </>
            )}
            {currentHandBook.extraFields.length
              ? currentHandBook.extraFields.map((item, index) => {
                  return (
                    <Col span={24} key={index}>
                      <label
                        htmlFor=""
                        className="mb-2"
                        style={{
                          color:
                            item.name === "code"
                              ? newHandbookValues[item.name]
                              : "initial",
                        }}
                      >
                        {item.title["name_" + myAccount.lang]}
                      </label>
                      {item.type ? (
                        <Input
                          type={item.type}
                          value={newHandbookValues[item.name]}
                          className="input-controller"
                          onChange={(event) =>
                            handleNewHandbookInput(
                              item.name,
                              event.target.value
                            )
                          }
                        />
                      ) : (
                        <FormSelect
                          height={60}
                          size="large"
                          name={item.name}
                          onChange={handleNewHandbookSelect}
                          placeholder={`${
                            item.title["name_" + myAccount.lang]
                          } tanlang`}
                          options={
                            item.static ? item.values : extraFieldData[index]
                          }
                          zIndex={1}
                          value={newHandbookValues[item.name]}
                        />
                      )}
                    </Col>
                  );
                })
              : null}
          </Row>
          <div className="text-center mt-3">
            <Button type="primary" size="large" onClick={handleNewHandbookSave}>
              {t("Yaratish")}
            </Button>
          </div>
        </Modal>
      </S.List>
    </>
  );
}
