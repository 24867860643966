import { handbookAxios } from "../utils/axios";
import handbookNames from "../constants/handbookNames";
import axios from "axios";

const handbooks = {};

handbooks.fetch = async (id) => {
  const requestFields = Object.values(handbookNames).filter(
    (item) => !item.static
  );
  const request = requestFields.reduce((acc, item) => {
    if (item.static) {
      return acc;
    }
    acc.push(
      handbookAxios.get(
        `/${item.url}/?ordering=id&page_size=10000&is_deleted=false`
      )
    );
    return acc;
  }, []);

  try {
    const res = await axios.all(request);
    const data = requestFields.reduce((acc, item, index) => {
      acc = { ...acc, [item.name]: res[index].data.results };
      return acc;
    }, {});

    return data;
  } catch (err) {
    console.error(err);
    return { error: err.response };
  }
};

handbooks.update = async ({ data, id }) => {
  // update if there is valid value to be send
  const { initialDate, isEdited, isInitialLoad, ...rest } = data;
  try {
    const { data: respondedData } = await handbookAxios.patch(
      `/cards/${id}/`,
      rest
    );
    return respondedData;
  } catch (err) {
    console.error(err);
    return { error: err.response };
  }
};

handbooks.update = async ({ data, id }) => {
  // update if there is valid value to be send
  const { initialDate, isEdited, isInitialLoad, ...rest } = data;
  try {
    const { data: respondedData } = await handbookAxios.patch(
      `/cards/${id}/`,
      rest
    );
    return respondedData;
  } catch (err) {
    console.error(err);
    return { error: err.response };
  }
};

handbooks.subAccidentType = async (id) => {
  try {
    const { data: respondedData } = await axios.get(
      `/sub_accident_types/?accident_type=${id}`
    );
    return respondedData;
  } catch (err) {
    return { error: err.response };
  }
};

export default handbooks;
