import React from 'react';
import { Select, DatePicker, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { t } from '../../utils';
import momentLocale from '../../utils/moment';
import { VscSettings } from 'react-icons/vsc'
import { BiCheckCircle } from "react-icons/bi";
import S from '../../styles/reports';

const initialState = {
  date_accident__gte: null,
  date_accident__lte: null,
  year: null,
  month: null,
};

export default function Filters({ filters, setFilters, handleParams, }) {
  const { regions } = useSelector(state => state.handbooks || {});
  const myAccount = useSelector(state => state.auth.user || {});
  const [showFilter, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const moment = momentLocale();

  const handleSelect = (name, value) => {
    setFilters((state) => ({ ...state, [name]: value }));
  };

  const handleDate = (name, value, str) => {
    if (name === 'date') {
      if (value) {
        setFilters(state => ({
          ...state,
          date_accident__gte: moment(str[0])?.startOf('date'),
          date_accident__lte: moment(str[1])?.endOf('date'),
          year: null,
          month: null,
        }))
      } else {
        setFilters(state => ({
          ...state,
          date_accident__gte: null,
          date_accident__lte: null,
        }))
      }
    } else if (name === 'year') {
      setFilters(state => ({
        ...state,
        year: value ?? null,
        month: null,
        date_accident__gte: null,
        date_accident__lte: null,
      }))
    } else {
      setFilters(state => ({
        ...state,
        month: value ? moment().set({ year: moment(state.year).year(), month: moment(value).format('MMMM') }) : null,
        date_accident__gte: null,
        date_accident__lte: null,
      }))
    }
  }

  const handleReset = () => {
    setFilters(initialState);
  }

  const handleToggle = () => setShowFilters(state => !state);

  const { month, year, date_accident__gte, date_accident__lte, region_id } = filters;

  return (
    <S.Filter className="filter">
      <div className="filter__toggler">
        <Button className={`filter__btn ${showFilter ? 'active' : ''}`} onClick={handleToggle}>
          <VscSettings size={18} />
        </Button>

      </div>

      {
        showFilter ?
          <div className="filter__wrapper">
            <div className="filter__inputs">
              <Select
                showSearch
                style={{ minWidth: 200, marginRight: 'auto' }}
                placeholder={t("Viloyat")}
                optionFilterProp="children"
                value={region_id || myAccount.region}
                onChange={(val) => handleSelect("region_id", val)}
                size="large"
                allowClear
                disabled={myAccount.region}
                filterOption={(input, option) => {
                  return (
                    option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                  );
                }}
              >
                {regions.map((item) => {
                  const title = item?.[`name_${myAccount.lang}`];
                  return (
                    <Select.Option value={item.id} label={title} key={item.id}>
                      {title}
                    </Select.Option>
                  );
                })}
              </Select>

              <DatePicker
                picker="year"
                value={year}
                size="large"
                placeholder={t('Yil')}
                disabledDate={current => moment(current) > moment() || moment(current) < moment('2010')}
                onChange={val => handleDate('year', val)}
              />

              <DatePicker
                picker="month"
                value={month}
                format={'MMMM'}
                placeholder={t('Oy')}
                size="large"
                onChange={val => handleDate('month', val)}
              />

              <DatePicker.RangePicker
                placeholder={[t("Sanadan"), t("Sanagacha")]}
                size="large"
                value={[date_accident__gte, date_accident__lte]}
                onChange={(val, str) => handleDate("date", val, str)}
              // format={dateFormatList}
              />

            </div>
            <div className="filter__btns">
              <Button type="dashed" onClick={handleReset} htmlType="reset">
                {t("Tozalash")}
              </Button>
              <Button loading={loading} onClick={handleParams} type="primary">
                {t("Qo'llash")}
                <BiCheckCircle className="has-icon" style={{ fontSize: 20 }} />
              </Button>
            </div>

          </div>

          :
          null
      }

    </S.Filter>
  )
}
