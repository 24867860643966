import {
  Button,
  Form,
  Input,
  message,
  Modal,
  notification,
  Select,
  Table,
  Divider,
  Spin,
  Switch,
  Menu,
  Dropdown,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { BiEditAlt, BiTrash,  BiHome, BiPlusCircle,  BiMinusCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { t } from '../utils';
import { AiOutlineDelete, AiOutlineMore } from "react-icons/ai";
import colors from "../styles/colors";
import Axios, { cancelToken } from "../utils/axios";
import Swal from "sweetalert2";


const Street = () => {
  const { districts, regions } = useSelector((state) => state.handbooks);
  const [regionId, setRegionId] = useState(1);
  const [districtsSato, setDistrictsSato] = useState("");
  const [streets, setStreets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenD, setIsModalOpenD] = useState(false);
  const { Item } = Form;
  const [currentStreet, setCurrentStreet] = useState(null);
  const [currentStreetD, setCurrentStreetD] = useState(null);
  const [query, setQuery] = useState("");

  
  
  // const handleSearch = async () => {
  //   try {
  //     axios.get(
  //       `https://apidtp.kash.uz/api/handbook/v1/geo/streets/?page_size=1000&name=${query}`
  //     ).then((res) => {
  //         setStreets(res?.data.results);
  //       })
  //       .finally(() => setIsLoading(false));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

 const handleSearch = async () => {
  // Ensure the query is not an empty string
  if (!query.trim() || null) {
    console.log("Search query is empty, not making the request.");


  if (districtsSato) {
      setIsLoading(true);
      axios
        .get(
          `https://apidtp.kash.uz/api/handbook/v1/geo/streets/?page_size=1000&district_saoto=${districtsSato}`
        )
        .then((res) => {
          setStreets(res?.data.results);
        })
        .finally(() => setIsLoading(false));
    }
  }
  else {
    try {
    await axios
      .get(
        `https://apidtp.kash.uz/api/handbook/v1/geo/streets/?page_size=1000&district_saoto=${districtsSato}&name=${query}`
      )
      .then((res) => {
        setStreets(res?.data.results);
      })
      .finally(() => setIsLoading(false));
  } catch (err) {
    console.log(err);
  }
   }

 
};




  

  const handleDelete = async (id) => {
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      text: t("Haqiqatdanham foydalanuvchini o'chirmoqchimisiz?"),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: "#E7E9EB",
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: "swal-danger",
    }).then(async ({ value }) => {
      if (value) {
        try {
          setIsLoading(true);
          await Axios.delete(`https://apidtp.kash.uz/api/handbook/v1/geo/streets/${id}/`);
          // await handlePage();
          message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
          setIsLoading(false);
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      }
    });
  };

   const handleStatus = async ({ id, is_active }) => {
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      text: t(""),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: "#E7E9EB",
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: "swal-danger",
    }).then(async ({ value }) => {
      if (value) {
        try {
          setIsLoading(true);
          await Axios.patch(`https://apidtp.kash.uz/api/handbook/v1/geo/streets/${id}/`, { is_active });
          // await handlePage();
          message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      }
    });
  };

  const handleClear = () => {
    setQuery("");
    // handlePage();
  };
  useEffect(() => {
    if (districtsSato) {
      setIsLoading(true);
      axios
        .get(
          `https://apidtp.kash.uz/api/handbook/v1/geo/streets/?page_size=1000&district_saoto=${districtsSato}`
        )
        .then((res) => {
          setStreets(res?.data.results);
        })
        .finally(() => setIsLoading(false));
      
      
       
    }
  }, [districtsSato]);

  

  const onFinish = (values) => {
    const districtId = districts.find(
      (item) => item.saoto  === districtsSato
    )?.id;

    if (currentStreet?.id) {
      setIsLoading(true);
      axios
        .patch(
          `https://apidtp.kash.uz/api/handbook/v1/geo/streets/${currentStreet.id}/`,
          {
            ...values,
            district: districtId,
          }
        )
        .then(() => {
          handleCancel();
          alert("Ko'cha Yangilandi");
           handleSearch();
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      axios
        .post(" https://apidtp.kash.uz/api/handbook/v1/geo/streets/", {
          ...values,
          district: districtId,
        })
        .then(() => {
          handleCancel();
          alert("Ko'cha yaratildi");
           handleSearch();
        })
        .finally(() => setIsLoading(false));
      
      
      
      
    }
  };

  const regionOptions = regions?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const districtOptions = districts
    ?.filter((item) => item.region === regionId)
    ?.map((item) => ({
      value: item.saoto,
      label: item.name,
    }));

  const showModal = (currentStreet) => {
    setIsModalOpen(true);
    setCurrentStreet(currentStreet);
  };

  const showModalD = (currentStreetD) => {
    setIsModalOpenD(true);
    setCurrentStreetD(currentStreetD);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCurrentStreet(null);
  };
  const handleCancelD = () => {
    setIsModalOpenD(false);
    setCurrentStreetD(null);
  };

  const columns = [
    {
      title: "Ko'cha nomi",
      dataIndex: "name",
      key: "name",
      //  sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Ko'cha turi",
      dataIndex: "street_type",
      key: "street_type",
    },
    {
      title: "Registratsiya raqami",
      dataIndex: "registered_number",
      key: "registered_number",
    },
    {
        key: "is_deleted",
        title: t("Holati"),
        dataIndex: "is_deleted",
        render: (val) => (
          <Tag color={val ? "green" : "red"} style={{
            border:"0px solid "
          }}>
            {val ? t("Faol") : t("Nofaol")}
          </Tag>
      ),
        width: 150,
        filters: [
          {
            text: t("Faol"),
            value: true,
          },
          {
            text: t("Nofaol"),
            value: false,
          },
        ],
        filterMultiple: false,
      onFilter: (value, record) => record.is_deleted === value,
    },
    
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
        
          <div
          style={{
            display: "flex",
            gap: "10px",
          }}
          >
            
            <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            onClick={() => showModal(record)}
            style={{
              fontSize: "22px",
            }}
                size="small"
                    type="dashed"
                    className="edit"
          >
                <BiEditAlt />
          </Button>
          </div>
          
          <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
           onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(record.id);
                    }}
            style={{
              fontSize: "22px",
            }}
                danger
                className="delete"
                size="small"
          >
           <BiTrash />
          </Button>
        </div>
        </div>
        </>

        
      ),
    },
  ];


  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <BiHome /> / Ma'lumotnomalar / Ko'chalar
      </div>
      <div
        style={{
          border: "1px solid #E2E4E7",
          marginTop: "20px",
          borderRadius: "6px",
        }}
      >
        <div
          style={{
            padding: "20px",
            fontSize: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E2E4E7",
          }}
        >
          Ko'chalar
          <Button onClick={showModal} type="primary" icon={<BiPlusCircle />}>
            Ma'lumotnoma qo'shish
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "20px",
            gap: "20px",
          }}
        >
          VIloyat:
          <Select
            size="large"
            style={{
              width: "300px",
            }}
            defaultValue={1}
            options={regionOptions}
            onSelect={(value) => setRegionId(value)}
          />
          Tuman:
          <Select
            size="large"
            style={{
              width: "300px",
            }}
            options={districtOptions}
            onSelect={(value) => setDistrictsSato(value)}
          />

          <Input.Search
      size="large"
      style={{ maxWidth: 300,  }}
      placeholder={t('Qidirish')}
      className={` handbook-search`}
      value={query}
      suffix={query.length ? <button className="clear-btn" style={{ height: 25 }} onClick={handleClear}>X</button> : <span />}
      onChange={(e) => {
        const { value } = e.target;
        setQuery(value);
      }}
      onSearch={handleSearch}
      onPressEnter={handleSearch}
          />
          

          Umumiy ko'chalar: 
          <span>{streets?.length} ta ko'cha mavjud</span>

        </div>

        <Table
          style={{
            padding: "20px",
          }}
          columns={columns}
          loading={isLoading}
          dataSource={streets}
          rowKey="id"
           rowClassName="clickable"
        />
        
      </div>
      {!currentStreet && (
        <Modal
          title="Ko'cha qoshish"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            name="myForm"
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            size="large"
            initialValues={{
              name: currentStreet?.name,
              type: currentStreet?.type,
              registered_number: currentStreet?.registered_number,
            }}
          >
            <Form.Item
              label="Nomi"
              name="name"
              rules={[{ required: true, message: "Ko'cha nomini kiriting!" }]}
            >
              <Input defaultValue={currentStreet?.name} />
            </Form.Item>
            <Form.Item
              label="Nomi O'zbek tilida"
              name="name_uz"
              rules={[{ required: true, message: "Ko'cha nomini kiriting!" }]}
            >
              <Input defaultValue={currentStreet?.name_uz} />
            </Form.Item>
            <Form.Item
              label="Nomi Rus tilida"
              name="name_ru"
              rules={[{ required: true, message: "Ko'cha nomini kiriting!" }]}
            >
              <Input defaultValue={currentStreet?.name_ru} />
            </Form.Item>
            <Form.Item
              label="Nomi Krill tilida"
              name="name_oz"
              rules={[{ required: true, message: "Ko'cha nomini kiriting!" }]}
            >
              <Input defaultValue={currentStreet?.name_eng} />
            </Form.Item>

            <Form.Item
              label="Turi"
              name="street_type"
              rules={[{ required: true, message: "Ko'cha turini kiriting!" }]}
            >
              <Select
                options={[
                  {
                    value: "ko'cha",
                    label: "ko'cha",
                  },
                  {
                    value: "berk ko'cha",
                    label: "berk ko'cha",
                  },
                  {
                    value: "muyulishli ko'cha",
                    label: "muyulishli ko'cha",
                  },
                  {
                    value: "o'rta ko'cha",
                    label: "o'rta ko'cha",
                  },
                  {
                    value: "o'tish yo'li",
                    label: "o'tish yo'li",
                  },
                  {
                    value: "tor ko'cha",
                    label: "tor ko'cha",
                  },
                  {
                    value: "yo'lak ko'cha",
                    label: "yo'lak ko'cha",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="Registratsiya raqami"
              name="registered_number"
              rules={[
                {
                  required: true,
                  message: "Ko'cha raqamini kiriting!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                style={{
                  width: "100%",
                }}
                htmlType="submit"
                loading={isLoading}
              >
                Saqlash
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}

      {currentStreet && (
        <Modal
          title="Tahrirlash"
          visible={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            name="myForm"
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            size="large"
            
            initialValues={currentStreet}
          >
            <Form.Item
              label="Nomi"
              name="name"
              rules={[{ required: true, message: "Ko'cha nomini kiriting!" }]}
            >
              <Input defaultValue={currentStreet?.name} />
            </Form.Item>
            <Form.Item
              label="Nomi O'zbek tilida"
              name="name_uz"
              rules={[{ required: true, message: "Ko'cha nomini kiriting!" }]}
            >
              <Input defaultValue={currentStreet?.name_uz} />
            </Form.Item>
            <Form.Item
              label="Nomi Rus tilida"
              name="name_ru"
              rules={[{ required: true, message: "Ko'cha nomini kiriting!" }]}
            >
              <Input defaultValue={currentStreet?.name_ru} />
            </Form.Item>
            <Form.Item
              label="Nomi Krill tilida"
              name="name_oz"
              rules={[{ required: true, message: "Ko'cha nomini kiriting!" }]}
            >
              <Input defaultValue={currentStreet?.name_eng} />
            </Form.Item>

            <Form.Item
              label="Turi"
              name="street_type"
              rules={[{ required: true, message: "Ko'cha turini kiriting!" }]}
            >
              <Select
                options={[
                  {
                    value: "ko'cha",
                    label: "ko'cha",
                  },
                  {
                    value: "berk ko'cha",
                    label: "berk ko'cha",
                  },
                  {
                    value: "muyulishli ko'cha",
                    label: "muyulishli ko'cha",
                  },
                  {
                    value: "o'rta ko'cha",
                    label: "o'rta ko'cha",
                  },
                  {
                    value: "o'tish yo'li",
                    label: "o'tish yo'li",
                  },
                  {
                    value: "tor ko'cha",
                    label: "tor ko'cha",
                  },
                  {
                    value: "yo'lak ko'cha",
                    label: "yo'lak ko'cha",
                  },
                ]}
              ></Select>
            </Form.Item>

            

            <Form.Item
              label="Registratsiya raqami"
              name="registered_number"
              rules={[
                {
                  required: true,
                  message: "Ko'cha raqamini kiriting!",
                },
              ]}
            >
              <Input defaultValue={currentStreet.registered_number} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                style={{
                  width: "100%",
                }}
                htmlType="submit"
                loading={isLoading}
              >
                Saqlash
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}


      {currentStreetD && (
        <Modal
          title="O'chirmoqchimisiz?"
          visible={isModalOpenD}
          onCancel={handleCancelD}
          footer={null}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              gap:"4px"
                }}
          >


            <Button
              type="default"
              onClick={handleCancelD}
                style={{
                  width: "100%",
                }}
                htmlType="submit"
                loading={isLoading}
              >
                Orqaga
            </Button>
            <Button
                type="primary"
                style={{
                  width: "100%",
                }}
                htmlType="submit"
                loading={isLoading}
              >
                Saqlash
              </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};



export default Street;
