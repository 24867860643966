import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Select, Spin } from "antd";
import { useSelector } from "react-redux";
import { getIdsFromArrayOfObjects, t } from "../utils";
import S from "../styles/formElements";
import { IoCheckbox, IoClose } from "react-icons/io5";
import colors from "../styles/colors";

const { Option } = Select;

export default function FormSelect(props) {
  const myAccount = useSelector((state) => state.auth.user || {});
  const selectRef = useRef(null);
  const {
    placeholder,
    height,
    name,
    options,
    onChange,
    parentKey,
    loading,
    disabled,
    activeKey,
    value,
    multiple,
    autoFocus,
    isNext,
  } = props;
  const fieldNames = Array.isArray(options) ? options : [];

  const validValue =
    value === null ||
    value === "" ||
    (Array.isArray(value) && value.length === 0)
      ? undefined
      : getIdsFromArrayOfObjects(value);
  useEffect(() => {}, [isNext]);
  return (
    <div style={{ width: "100%" }} ref={selectRef}>
      <S.Select
        showSearch
        multiple={multiple}
        mode={multiple ? "multiple" : "none"}
        getPopupContainer={(el) => selectRef.current}
        placeholder={t(placeholder)}
        onChange={(val) => onChange(name, val, activeKey, parentKey)}
        autoFocus={autoFocus}
        height={multiple ? "auto" : height}
        borderColor={isNext ? (value ? "success" : "danger") : "success"}
        name={name}
        showAction={["focus", "click"]}
        value={validValue}
        allowClear
        menuItemSelectedIcon={null}
        className="custom-select-content focusable"
        dropdownClassName="custom-select-dropdown focusable"
        removeIcon={
          <IoClose style={{ fontSize: 30, fill: colors.success, padding: 3 }} />
        }
        notFoundContent={loading ? <Spin size="small" /> : null}
        size="large"
        disabled={disabled}
        listItemHeight={42}
        filterOption={(input, option) => {
          return (
            option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
      >
        {fieldNames.map((item) => {
          const translationBasedName =
            item[`name_${myAccount.lang}`] || item["name"];
          let title = `${
            !item.static ? `${item.id ? item.id + "." : ""}` : ""
          } ${translationBasedName}`;
          if (multiple) {
            title = `${item.id + "."} ${translationBasedName}`;
          } else if (name === "road_list") {
            title = `${item.code + "-"} ${translationBasedName}`;
          }

          return (
            <Option value={item.id || item.value} label={title} key={item.id}>
              {multiple ? (
                <>
                  <div className="checkbox-wrapper">
                    <IoCheckbox
                      style={{
                        fontSize: 30,
                        fill: colors.success,
                        marginLeft: -2,
                      }}
                      className="checkicon"
                    />
                    <span className="empty-checkbox"></span>
                  </div>
                  {title}
                </>
              ) : (
                ` ${title} ${item.saoto || ""}`
              )}
            </Option>
          );
        })}
      </S.Select>
    </div>
  );
}

FormSelect.defaultProps = {
  height: 60,
  size: "",
  options: [],
  onChange: () => undefined,
  placeholder: "",
  tabIndex: 1,
  value: "",
  multiple: false,
  autoFocus: false,
};

FormSelect.propTypes = {
  height: PropTypes.number,
  size: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  autoFocus: PropTypes.bool,
  multiple: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
