import React, { useEffect, useState, useMemo } from "react";
import { Table, Button, Divider, Space, Tooltip } from "antd";
import Axios, { cancelToken } from "../../utils/axios";
import moment from "moment";
import { isReadOnlyUser, t } from "../../utils";
import { statusColors } from "../../constants";
import S from "../../styles/cards";
import { BiPlusCircle } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { IoMan } from "react-icons/io5";
import colors from "../../styles/colors";
import { useHistory } from "react-router-dom";
import EmptyParticipants from "../../components/EmptyCardData";
import { useSelector } from "react-redux";
import Filters from "../../components/Cards/Filters";

export default function List() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, total: 0, size: 10 });
  const myAccount = useSelector((state) => state.auth.user);
  const history = useHistory();
  const source = cancelToken();
  const filters = useSelector(
    (state) =>
      state.cardFilters || {
        date_accident__gte: moment(moment().startOf("year")).utc(true).format(),
        date_accident__lte: moment(moment().endOf("date")).utc(true).format(),
      }
  );

  const defaultPage =
    new URLSearchParams(window.location.search).get("page") || 1;
  const defaultSize =
    new URLSearchParams(window.location.search).get("page_size") || 10;

  const [activeSort, setActiveSort] = useState({
    orderDirection: "",
    ordering: "",
  });

  const filterParams = useMemo(() => {
    let data = "";
    const notAllowedKeys = ["date_accident"];
    for (let x in filters) {
      if (!notAllowedKeys.includes(x)) {
        if (Array.isArray(filters[x]) && filters[x].length) {
          data += `&${x}=${filters[x].join(",")}`;
        } else if (!Array.isArray(filters[x]) && filters[x]) {
          data += `&${x}=${filters[x]}`;
        }
      }
    }

    return data;
  }, [filters]);

  useEffect(() => {
    handlePage(defaultPage, defaultSize);
    return () => source.cancel("Component unmounted");
  }, []);

  const handlePage = async (page = 1, size = 10, reset = false) => {
    const { ordering, orderDirection } = activeSort;
    let query = "";

    for (let x in activeSort) {
      if (
        activeSort[x] !== "" &&
        activeSort[x] !== null &&
        x !== "orderDirection" &&
        activeSort[x] !== undefined
      ) {
        if (x === "ordering") {
          const orderField =
            orderDirection === "descend" ? `-${ordering}` : ordering;
          query += `${x}=${orderField}&`;
        } else {
          query += `${x}=${activeSort[x]}&`;
        }
      }
    }

    try {
      const date_accident__gte = moment(moment().startOf("year"))
        .utc(true)
        .format();
      const date_accident__lte = moment(moment().endOf("date"))
        .utc(true)
        .format();
      setLoading(true);
      const params = reset
        ? `date_accident__gte=${date_accident__gte}&date_accident__lte=${date_accident__lte}`
        : filterParams;
      const res = await Axios.get(
        `/cards/?${query}${params}&page_size=${size}&page=${page}`,
        { cancelToken: source.token }
      );
      if (res.data.results) {
        setData(res.data.results);
        setPagination({ page: parseInt(page), total: res.data.count, size });
        history.push(`/cards/?page=${page}&page_size=${size}`);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    const page = new URLSearchParams(window.location.search).get("page") || 1;
    const size =
      new URLSearchParams(window.location.search).get("page_size") || 10;
    if (activeSort.ordering) {
      handlePage(page, size);
    }

    return () => source.cancel("Component unmounted");
  }, [activeSort.ordering, activeSort.orderDirection]);

  const { injured, died, participants_count } = useMemo(
    () =>
      data.reduce(
        (acc, item) => {
          acc.injured += item.injured;
          acc.died += item.died;
          acc.participants_count += item.participants_count;
          return acc;
        },
        { died: 0, injured: 0, participants_count: 0 }
      ),
    [data]
  );

  const columns = useMemo(
    () => [
      {
        title: t("YTH ID raqami"),
        dataIndex: "id",
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b, order) => {
          return a.id - b.id;
        },
      },
      {
        title: t("YTH hisob varaqasi"),
        dataIndex: "accident_number",
        render: (val, record) => {
          return (
            <div>
              <p>{record.accident_number}</p>
            </div>
          );
        },
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b, order) => {
          return a.accident_number - b.accident_number;
        },
      },
      {
        title: <div className="text-center">{t("Holati")}</div>,
        dataIndex: "status",
        render: (val, record) => {
          const status = record.is_deleted
            ? "excluded"
            : val === "filling" && record.is_late
            ? "filling_late"
            : val;
          return (
            <Button
              shape="round"
              style={{
                backgroundColor: statusColors[status].bg,
                color: statusColors[status].color,
                borderColor: statusColors[status].bg,
              }}
              size={"small"}
            >
              {statusColors[status][myAccount.lang || "uz"]}
            </Button>
          );
        },
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b, order) => {
          return a.accident_number - b.accident_number;
        },
      },
      {
        title: <div className="text-center">{t("Sana")}</div>,
        dataIndex: "date_accident",
        render: (val, record) => (
          <div className="text-center">{moment(val).format("DD.MM.YYYY")}</div>
        ),
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b, order) => {
          return a.date_accident - b.date_accident;
        },
      },
      {
        title: t("Tuman"),
        dataIndex: "district",
        render: (val, record) =>
          val?.id ? val[`name_${myAccount.lang}`] : val,
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b, order) => {
          return a.district - b.district;
        },
      },
      {
        title: t("Xodim"),
        dataIndex: "created_by",
        render: (val) =>
          `${val?.first_name ?? ""} ${val?.last_name ?? ""} - ${val?.rank}`,
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b, order) => {
          return a.created_by - b.created_by;
        },
      },
      {
        title: t("Geolokatsiya"),
        dataIndex: "location",
        render: (val, record) => (
          <div style={{ textAlign: "center" }}>
            {" "}
            {record.location ? (
              <TiTick style={{ color: "green", fontSize: "1.4rem" }} />
            ) : (
              <ImCross style={{ color: "red", fontSize: "0.9rem" }} />
            )}
          </div>
        ),
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b, order) => {
          return a.location - b.location;
        },
        // `${val?.first_name ?? ""} ${val?.last_name ?? ""} - ${val?.rank}`
        // `${record.location == "" ? <TiTick /> : "locatsiya mavjud"}`,
      },
      {
        title: "Ishtirokchi soni",
        dataIndex: "ordering",
        render: (val, record) => {
          console.log(record, "this is record");
          return (
            <p style={{ textAlign: "center" }}>
              {record.participants_count} - ta
            </p>
          );
        },
      },
      // {
      //   title: t("Ishirokchi holati"),
      //   dataIndex: "ordering",
      //   render: (val, record) => {
      //     console.log(record, "this is record");
      //     return (
      //       <Tooltip
      //         title={
      //           <p>
      //             {record.died} - Vafot etgan <br /> {record.injured} -
      //             Jarohatlangan <br />{" "}
      //             {record.participants_count - record.injured - record.died} -
      //             Zarar yetmagan
      //           </p>
      //         }
      //       >
      //         <p
      //           style={{
      //             textAlign: "center",
      //             whiteSpace: "nowrap",
      //             display: "flex",
      //             alignItems: "center",
      //           }}
      //         >
      //           {record.died} - <IoMan size="18px" color="red" />{" "}
      //           {record.injured}
      //           - <IoMan size="18px" color="orange" />{" "}
      //           {record.participants_count - record.injured - record.died} -{" "}
      //           <IoMan size="18px" color={colors.success} />
      //         </p>
      //       </Tooltip>
      //     );
      //   },

      //   filterMultiple: false,
      //   filters: [
      //     { text: t("Vafot etgan"), value: "died" },
      //     { text: t("Jarohatlangan"), value: "injured" },
      //   ],
      //   filteredValue: [activeSort.ordering],
      // },
    ],
    [myAccount.lang, activeSort.ordering]
  );

  function handleCreateCardLink() {
    // dispatch({ type: types.CARD_COMPLETED });
    history.push("/cards/new");
  }

  return (
    <S.List>
      <div className="d-flex page-wrapper">
        <div className="col-left">
          <div className="px-4">
            <h1>{t("YTH kartochkalari")}</h1>
            <div className="d-flex mb-4">
              {/* <Input.Search id="search-bar" /> */}
              {!isReadOnlyUser() ? (
                <Button
                  onClick={handleCreateCardLink}
                  id="add-card"
                  type="primary"
                  className="ml-auto"
                >
                  {t("YTH Kartochka yaratish")}
                  {<BiPlusCircle className="has-icon" />}
                </Button>
              ) : null}
            </div>
          </div>
          <Divider style={{ marginLeft: -40, width: "calc(100% + 40px)" }} />
          {data.length || loading ? (
            <Table
              dataSource={data}
              columns={columns}
              loading={loading}
              className="card-list"
              scroll={{ x: true }}
              rowKey="id"
              rowClassName="clickable"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => history.push(`/cards/${record.id}/`), // click row
                };
              }}
              onChange={(sort, filter, column) => {
                let filters = {};
                for (let x in filter) {
                  if (filter[x] !== null) {
                    filters = { ...filters, [x]: filter[x][0] };
                  } else {
                    delete filters[x];
                  }
                }
                setActiveSort((state) => ({
                  ...state,
                  ...filters,
                  ordering: filter?.ordering?.[0] || column?.field,
                  orderDirection: column?.order || "descend",
                  is_deleted: false,
                }));
              }}
              pagination={{
                hideOnSinglePage: false,
                pageSize: pagination.size,
                current: pagination.page,
                total: pagination.total,
                showSizeChanger: true,
                showSizeChangerOptions: {},
                onChange: (page, size) => handlePage(page, size),
                pageSizeOptions: [10, 20, 30, 50],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} / ${total}`,
                locale: { items_per_page: "/ " + t("Sahifa") },
              }}
            />
          ) : (
            <EmptyParticipants
              title={t("Ma'lumot topilmadi")}
              text={t("Qidiruv bo'yicha ma'lumot topilmadi")}
              onClick={() => undefined}
              customButton={true}
            />
          )}

          <Divider style={{ marginLeft: -40, width: "calc(100% + 40px)" }} />
          <Space size="large">
            <div className="d-flex">
              <IoMan size="18px" className="mr-1" color={"red"} />
              {t("O'lim bilan bog'lik")} - {died}
            </div>
            <div className="d-flex">
              <IoMan size="18px" className="mr-1" color={"#F59C15"} />
              {t("Jarohatlanish")} - {injured}
            </div>
            <div className="d-flex">
              <IoMan size="18px" className="mr-1" color={colors.success} />
              {t("Sog'liqqa zarar yetmagan")} -{" "}
              {participants_count - (died + injured)}
            </div>
          </Space>
        </div>
        <div className="b-1 col-right b-radius p-0">
          <Filters
            filters={filters}
            loading={loading}
            fetchData={handlePage}
            statistics
            // updateFilters={setFilters}
          />
        </div>
      </div>
    </S.List>
  );
}
