/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Space,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Divider,
  Alert,
  Button,
  Input,
  message,
  Image,
  Select,
} from "antd";

import S from "../../../styles/cards";

import {
  t,
  getDescription,
  getNotNullValues,
  getHandbookName,
  getOptionsByFieldNameId,
  toUpperCase,
} from "../../../utils";
import { Formik, Form, ErrorMessage } from "formik";
import * as meta from "../../../constants/cardOptions";
import { FormSelect } from "../../../components";
import { ReactComponent as QuestionMark } from "../../../assets/images/card/info.svg";
import { BsArrowRight } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import TextArea from "antd/lib/input/TextArea";
import imageCar from "../../../assets/images/card/photo.jpg";
import NewCardContext from "../../../contexts/newCardContext";
import api from "../../../api/cards";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../store/cards/actions";
import { getSunrise, getSunset } from "sunrise-sunset-js";
import momentLocale from "../../../utils/moment";
import UploadFile from "../../../components/UploadHandler";
import { useNetwork } from "../../../utils/hooks";
import { debounce } from "lodash";
import axios from "../../../utils/axios";
import "./stepOne.css";

export default function AddCard(props) {
  const fields = useSelector((state) => state.card || {});
  const myAccount = useSelector((state) => state.auth.user || {});
  const dispatch = useDispatch();
  const attachmentRef = useRef();
  const { handleActiveTab, setMapVisible } = useContext(NewCardContext);
  const timeRef = useRef("");
  const fullDateRef = useRef(null);
  const fieldsRef = useRef("");
  fieldsRef.current = fields;
  const moment = momentLocale();
  const isOnline = useNetwork();
  const [errMsg, setErrMsg] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [currentYthTypeId, setCurrentYyhTypeId] = useState("");
  const [currentSub, setCurrentSub] = useState("");
  const [streets, setStreets] = useState([]);

  useEffect(() => {
    dispatch(
      actions.stepOne({
        region: myAccount.region,
        district: myAccount.district,
      })
    );
  }, [myAccount.region, myAccount.district]);

  const handleStepOne = useCallback(async () => {
    setIsNext(true);

    if (!fields.description || fields.description.length < 100) {
      message.error(
        t(
          "YTH qisqacha tavsifi (bayoni) 100 ta harfdan kam bo'lmasligi kerak."
        ),
        5
      );
      return;
    }

    if (!fields.id) {
      const data = await api.create(fields);
      if (!data?.error) {
        const { status, id } = data;
        dispatch(actions.stepOne({ status, id }));
        handleActiveTab("f6");
        message.success(t("Ma'lumot muvaffaqiyatli yaratildi"));
      } else {
        // let messages = [];
        // for (let x in data.error.data) {
        //   messages.push(<li key={x}>{getHandbookName(x)}: {data.error.data[x].join(', ')}</li>)
        // }

        // return message.error(messages, 10)
        message.error(
          t(
            "YTH kartochka yaratilmadi. Yaratish uchun YTH turini, sodir bo'lgan sana va vaqtni kirish"
          ),
          5
        );
      }
    } else {
      handleActiveTab("f6");
    }
  }, [fields, t, dispatch]);

  const handleSelect = useCallback(
    (name, value) => {
      console.log("name", name);
      let data = { [name]: value };
      if (name === "region") {
        data = { region: value, district: null };
      } else if (name === "road_list") {
        const selectedRoad = road_list.find((i) => i.id === value);
        data = {
          road_list: value,
          street_significance: selectedRoad?.street_significance,
        };
      }
      if (name === "accident_type") {
        setCurrentYyhTypeId(value);
        setCurrentSub("");
      }

      if (name === "sub_accident_type") {
        setCurrentSub(value);
      }

      if (name === "district") {
        const currentDistrictSaoto = districts.find(
          (item) => item.id === value
        );
        axios
          .get(
            `https://apidtp.kash.uz/api/handbook/v1/geo/streets/?page_size=1000&district_saoto=${currentDistrictSaoto?.saoto}`
          )
          .then((res) => setStreets(res?.data.results));
      }
      dispatch(actions.stepOne(data));
    },
    [fields]
  );

  const handleStreetSelect = (value) => {
    console.log(value);
    handleSelect("street_name", value);
  };

  const createCardHandler = useCallback(
    debounce(async () => {
      const {
        accident_number,
        date_accident,
        accident_time,
        part_of_day,
        accident_type,
        sub_accident_type,
        id,
        status,
        region,
        district,
        location,
      } = fields;
      if (!id && !status) {
        const initialDataToSend = {
          accident_type,
          sub_accident_type,
          date_accident,
          part_of_day,
          accident_number,
          region,
          district,
          location,
        };
        const initialRequiredFields =
          date_accident &&
          accident_type &&
          sub_accident_type &&
          accident_time &&
          region &&
          district &&
          location;
        if (initialRequiredFields) {
          const res = await api.create(getNotNullValues(initialDataToSend));
          if (res.error) {
            let messages = [];
            for (let x in res.error) {
              messages.push(
                <li key={x}>
                  {getHandbookName(x)}: {res.error[x].join(", ")}
                </li>
              );
            }
            return message.error(messages, 10);
          }
          const { status, id, card_number, is_late } = res;
          dispatch(
            actions.stepOne({ ...fields, status, id, card_number, is_late })
          );
        }
      }
    }, 1000),
    [fields]
  );

  useEffect(() => {
    const {
      date_accident,
      accident_type,
      sub_accident_type,
      accident_time,
      status,
      id,
      district,
      region,
      location,
    } = fields;
    const initialRequiredFields =
      date_accident &&
      accident_type &&
      sub_accident_type &&
      accident_time &&
      region &&
      district &&
      location;
    if (!id && !status && initialRequiredFields && isOnline) {
      createCardHandler();
      return createCardHandler.cancel;
    }
  }, [fields]);

  const handleDatepicker = useCallback(
    (name, value, str) => {
      const data = { [name]: value };
      dispatch(actions.stepOne(data));
    },
    [fields]
  );

  const handleInput = useCallback((name, event, isUpperCase = false) => {
    let { value } = isUpperCase ? toUpperCase(event).target : event.target;
    const data = { [name]: value };
    dispatch(actions.stepOne(data));
  }, []);

  const handleFulltime = useCallback(
    (val) => {
      const data = {
        accident_day: val.startOf("date").format("DD"),
        accident_month: val.format("MM"),
        accident_year: val.format("YYYY"),
        date_accident: val.format(),
      };
      dispatch(actions.stepOne(data));
    },
    [fields]
  );

  useEffect(() => {
    const { accident_day, accident_month, accident_year, accident_time } =
      fields;

    if (accident_day && accident_month && accident_year?.length === 4) {
      const min = !isNaN(accident_time?.slice(3, 5))
        ? accident_time?.slice(3, 5)
        : "00";
      const hour = !isNaN(accident_time?.slice(0, 2))
        ? accident_time?.slice(0, 2)
        : "00";
      const date = moment()
        .set({
          year: accident_year ? accident_year : moment().format("YYYY"),
          month: accident_month
            ? accident_month - 1
            : moment().startOf("date").format("MM"),
          date: accident_day
            ? accident_day
            : moment().startOf("date").format("DD"),
          hour: hour,
          minute: min,
        })
        .utc(true)
        .format();
      dispatch(actions.stepOne({ date_accident: date }));
    }
  }, [
    fields.accident_day,
    fields.accident_month,
    fields.accident_year,
    fields.accident_time,
  ]);

  useEffect(() => {
    const { accident_time, date_accident = Date.now() } = fields;
    const coordinates = { lat: 41.4541, lng: 69.592 }; //Tashkent
    const morning = moment(
      getSunrise(
        coordinates.lat,
        coordinates.lng,
        new Date(moment(date_accident).valueOf())
      )
    ).format("HH:mm");
    const night = moment(
      getSunset(
        coordinates.lat,
        coordinates.lng,
        new Date(moment(date_accident).valueOf())
      )
    ).format("HH:mm");

    if (accident_time?.length === 5) {
      if (accident_time >= morning && accident_time <= night) {
        dispatch(actions.stepOne({ part_of_day: "day" }));
      } else {
        dispatch(actions.stepOne({ part_of_day: "night" }));
      }
    }
  }, [fields.accident_time, fields.date_accident]);

  const {
    accident_causals,
    accident_types,
    sub_accident_types,
    districts,
    illuminations,
    regions,
    residence_types,
    road_conditions,
    road_list,
    road_parts,
    road_surfaces,
    street_significances,
    weather_conditions,
  } = useSelector((state) => state.handbooks);

  const dateHandler = (value, type) => {
    const current = {
      day: Number(
        moment().date() > 10 ? moment().date() : `0${moment().date()}`
      ),
      month: Number(
        moment().month() + 1 > 10
          ? moment().month() + 1
          : `0${moment().month() + 1}`
      ),
      year: moment().year() > 10 ? moment().year() : `0${moment().year()}`,
    };

    switch (type) {
      case "day":
        const day =
          value >
          moment(
            fields.accident_year + "-" + fields.accident_month
          ).daysInMonth()
            ? moment(
                fields.accident_year + "-" + fields.accident_month
              ).daysInMonth()
            : value;
        if (
          moment(
            `${fields.accident_year}-${fields.accident_month}-${day}`
          ).valueOf() > moment().valueOf()
        ) {
          handleDatepicker("accident_day", current.day);
          handleDatepicker("accident_month", current.month);
          handleDatepicker("accident_year", current.year);
          setErrMsg("Diqqat YTH sanaga kelajak sana kiritildi");
        } else {
          handleDatepicker("accident_day", day);
          setErrMsg(false);
        }
        break;

      case "month":
        const month = value > 12 ? 12 : value;
        const day2 =
          fields.accident_day >
          moment(fields.accident_year + "-" + value).daysInMonth()
            ? moment(fields.accident_year + "-" + value).daysInMonth()
            : fields.accident_day;
        if (
          moment(
            `${fields.accident_year}-${month}-${fields.accident_day}`
          ).valueOf() > moment().valueOf()
        ) {
          handleDatepicker("accident_day", current.day);
          handleDatepicker("accident_month", current.month);
          handleDatepicker("accident_year", current.year);
          setErrMsg("Diqqat YTH oyga kelajak oy kiritildi");
        } else {
          handleDatepicker("accident_month", month);
          handleDatepicker("accident_day", day2);
          setErrMsg(false);
        }
        break;

      case "year":
        const year = value > current.year ? current.year : value;
        if (
          moment(
            `${year}-${fields.accident_month}-${fields.accident_day}`
          ).valueOf() > moment().valueOf()
        ) {
          handleDatepicker("accident_day", current.day);
          handleDatepicker("accident_month", current.month);
          handleDatepicker("accident_year", current.year);
          setErrMsg("Diqqat YTH yilga kelajak yili kiritildi");
        } else {
          handleDatepicker("accident_year", year);
          setErrMsg(false);
        }
        break;

      default:
        break;
    }
  };

  const getIdIfObject = useCallback(
    (val) => {
      if (Array.isArray(val)) {
        return val.map((item) => {
          if (typeof item === "number") {
            return item;
          } else if (typeof item === "object") {
            return item.id;
          } else {
            return item;
          }
        });
      }
      return val?.id ? val.id : val;
    },
    [fields]
  );

  const currentSubImage = sub_accident_types?.find(
    (item) => item.id === currentSub
  );
  return (
    <>
      <S.NewCard className="br-1">
        <Formik
        // initialValues={initialvalues}
        // validationSchema={stepOneSchema}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off">
              <article className="px-4 mb-5">
                <Row gutter={[16]}>
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="date">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_number")}
                        trigger={["click", "hover"]}
                      >
                        {t("YTH ro'yhatga olingan raqam")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="accident_number"
                        className="error-message"
                        component="span"
                      />
                      <Input
                        type="text"
                        value={fields.accident_number}
                        onChange={(val) =>
                          handleInput("accident_number", val, true)
                        }
                        name="accident_number"
                        className={`input-controller focusable ${
                          isNext ? (fields.accident_number ? "" : "error") : ""
                        }`}
                        placeholder={t("YTH ro'yhatga olingan raqam")}
                        // tabIndex={1}
                        autoComplete="off"
                        id="accident_number"
                        autoFocus={true}
                      />
                    </div>
                  </Col>
                </Row>
              </article>
              {/* ----------- 1.2 --------- */}
              <article className="px-4">
                <h2 htmlFor="type-DTP" className="sub-title mb-4">
                  1.1 {t("YTH sodir bo'lgan vaqt")}
                </h2>
                <Row gutter={[16]}>
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="date">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_date")}
                        trigger={["click", "hover"]}
                      >
                        {t("Sana")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <Row gutter={[12]}>
                      <Col span={7}>
                        <InputMask
                          name="accident_day"
                          className={`input-controller focusable ${
                            isNext ? (fields.accident_day ? "" : "error") : ""
                          }`}
                          value={fields.accident_day}
                          mask="99"
                          max="31"
                          min="1"
                          maskChar=""
                          inputMode="numeric"
                          // tabIndex={6}
                          placeholder={t("Sana")}
                          alwaysShowMask="true"
                          onChange={(event) =>
                            dateHandler(event.target.value, "day")
                          }
                        />
                        {moment(fields.date_accident).isValid() ? (
                          <div className="mt-1 text-grey">
                            {t("Hafta kuni")} -{" "}
                            {moment(fields.date_accident).isoWeekday() ?? ""}{" "}
                            <br />
                            {moment(fields.date_accident)
                              .subtract(1, "day")
                              .format("dddd")}
                          </div>
                        ) : null}
                      </Col>
                      <Col span={7}>
                        <InputMask
                          name="accident_month"
                          className={`input-controller focusable ${
                            isNext ? (fields.accident_month ? "" : "error") : ""
                          }`}
                          value={fields.accident_month}
                          mask="99"
                          max="12"
                          min="1"
                          maskChar=""
                          inputMode="numeric"
                          // tabIndex={6}
                          placeholder={t("Oy")}
                          alwaysShowMask="true"
                          onChange={(event) =>
                            dateHandler(event.target.value, "month")
                          }
                        />
                        {moment(fields.date_accident).isValid() && (
                          <div className="mt-1 text-grey">
                            {moment(
                              `${fields.accident_year}-${fields.accident_month}-${fields.accident_day}`
                            ).format("MMMM")}
                          </div>
                        )}
                      </Col>
                      <Col span={7}>
                        <InputMask
                          name="accident_year"
                          className={`input-controller focusable ${
                            isNext ? (fields.accident_year ? "" : "error") : ""
                          }`}
                          value={fields.accident_year}
                          mask="9999"
                          max={moment().format("YYYY")}
                          min="1"
                          maskChar=""
                          inputMode="numeric"
                          // tabIndex={6}
                          placeholder={t("Yil")}
                          alwaysShowMask="true"
                          onChange={(event) =>
                            dateHandler(event.target.value, "year")
                          }
                        />
                      </Col>
                      <Col span={3}>
                        <DatePicker
                          name="date_accident"
                          className="input-controller calendar-btn clickable"
                          size="large"
                          // tabIndex={5}
                          disabledDate={(current) => current > moment()}
                          allowClear={false}
                          ref={fullDateRef}
                          showToday={false}
                          onChange={(val) => handleFulltime(val)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {fields.accident_day &&
                  fields.accident_year &&
                  fields.accident_month &&
                  moment().diff(
                    moment(
                      `${fields.accident_year}-${fields.accident_month}-${fields.accident_day}`
                    ),
                    "days"
                  ) >= 3 && (
                    <Row className="late-entry">
                      <Col span={6}></Col>
                      <Col span={18}>
                        <Row>
                          <Col span={24}>
                            <Alert
                              showIcon
                              description={t(
                                "Diqqat YTH sanasi 3 kundan avval"
                              )}
                              icon={
                                <box-icon
                                  style={{ fill: "#F51010", marginRight: 7 }}
                                  name="comment-error"
                                  flip="horizontal"
                                ></box-icon>
                              }
                              // closable
                              className="my-2"
                              style={{
                                color: "#F51010",
                                backgroundColor: "rgba(245, 16, 16, 0.08)",
                                border: "none",
                                padding: 11,
                                display: "inline-flex",
                              }}
                            />
                          </Col>
                          <Col span={24}>
                            <div>
                              <UploadFile
                                accept={".doc, .docx, .pdf"}
                                files={fields.fileList}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                {errMsg && (
                  <Row className="late-entry">
                    <Col span={6}></Col>
                    <Col span={18}>
                      <Row>
                        <Col span={24}>
                          <Alert
                            showIcon
                            description={t(errMsg)}
                            icon={
                              <box-icon
                                style={{ fill: "#F51010", marginRight: 7 }}
                                name="comment-error"
                                flip="horizontal"
                              ></box-icon>
                            }
                            // closable
                            className="my-2"
                            style={{
                              color: "#F51010",
                              backgroundColor: "rgba(245, 16, 16, 0.08)",
                              border: "none",
                              padding: 11,
                              display: "inline-flex",
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="date">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_time")}
                        trigger={["click", "hover"]}
                      >
                        {t("Soat")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                    <span>{t("24 soatlik format")}</span>
                  </Col>
                  <Col span={18}>
                    <Space>
                      <InputMask
                        name="accident_time"
                        className={`input-controller focusable ${
                          isNext
                            ? !fields?.accident_time?.includes("_")
                              ? ""
                              : "error"
                            : ""
                        }`}
                        value={fields?.accident_time}
                        mask="99:99"
                        max="2359"
                        maskChar="_"
                        inputMode="numeric"
                        ref={timeRef}
                        // tabIndex={6}
                        alwaysShowMask="true"
                        onChange={(event) => {
                          const { value } = event.target;
                          const hour = value.slice(0, 2);
                          const min = value.slice(3, 5);
                          // Validate hour and min in the valide time range
                          if (hour > 23 && min > 59) {
                            handleDatepicker("accident_time", "23:59");
                          } else if (hour > 23) {
                            handleDatepicker("accident_time", "23:" + min);
                          } else if (min > 59) {
                            handleDatepicker("accident_time", hour + ":59");
                          } else {
                            handleDatepicker(
                              "accident_time",
                              event.target.value
                            );
                          }
                        }}
                      />
                    </Space>
                  </Col>
                </Row>
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("part_of_day")}
                        trigger={["click", "hover"]}
                      >
                        {t("Kunning vaqt")}{" "}
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="part_of_day"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        height={60}
                        size="large"
                        name="part_of_day"
                        value={getIdIfObject(fields?.part_of_day)}
                        onChange={handleSelect}
                        placeholder="Kunning vaqtini tanlang"
                        options={meta.typeOfDay}
                        // tabIndex={7}
                      />
                    </div>
                  </Col>
                </Row>
              </article>
              <Divider style={{ margin: "40px 0" }} />
              <article className="px-4">
                <h2 htmlFor="type-DTP" className="sub-title">
                  1.2 {t("YTH turi")}
                </h2>
                <Row gutter={[16]}>
                  <Col span={6} className="label-wrapper">
                    <label>
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_type")}
                        trigger={["click", "hover"]}
                      >
                        {t("YTH turi")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>

                  <Col span={18}>
                    <div>
                      <div className="input-wrapper">
                        <ErrorMessage
                          name="accident_type"
                          className="error-message"
                          component="span"
                        />
                        <FormSelect
                          isNext={isNext}
                          height={60}
                          size="large"
                          name="accident_type"
                          onChange={handleSelect}
                          placeholder="YTH turini tanlang"
                          options={accident_types}
                          // tabIndex={8}
                          value={getIdIfObject(fields.accident_type)}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "2rem",
                          gap: "4rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",

                            marginTop: "2rem",

                            width: "100%",
                          }}
                        >
                          <ErrorMessage
                            name="sub_accident_type"
                            className="error-message"
                            component="span"
                          />

                          <FormSelect
                            height={60}
                            size="large"
                            name="sub_accident_type"
                            onChange={handleSelect}
                            placeholder="Sub turini tanlang"
                            options={sub_accident_types?.filter(
                              (item) => item.accident_type === currentYthTypeId
                            )}
                            value={
                              sub_accident_types?.find(
                                (item) => item.id === fields?.sub_accident_type
                              )?.accident_type === fields?.accident_type
                                ? sub_accident_types?.find(
                                    (item) =>
                                      item.id === fields?.sub_accident_type
                                  ).name
                                : ""
                            }
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: "2rem",
                            gap: "4rem",
                            zIndex: "100",
                          }}
                        >
                          <Image src={currentSubImage?.image.file} />
                        </div>

                        {/* <Button type="primary" onClick={showModal}>
                          Sub YTH turi
                        </Button>

                        <Modal
                          title="Sub YTH turi"
                          visible={isModalOpen}
                          onOk={handleOk}
                          onCancel={handleCancel}
                          zIndex={99999}
                        >
                          {sub_accident_types.map(
                            (item, index) => (
                              <>
                                <Radio.Group
                                  onChange={handleSelect}
                                  value={value}
                                  // value={getIdIfObject(fields.accident_type)}
                                  style={{
                                    display: "flex",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Radio value={index}>
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10rem",
                                        width: "100%",
                                      }}
                                    >
                                      {item.name}
                                      <img
                                        width={"20vw"}
                                        height={"20vw"}
                                        src={item.image.file}
                                        alt="YTH turi"
                                      />
                                    </div>
                                  </Radio>
                                </Radio.Group>
                              </>
                            )
                            // console.log(item.name)
                          )}
                        </Modal> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </article>

              <Divider style={{ margin: "40px 0" }} />

              {/* ----------- 1.3 --------- */}
              <article className="px-4">
                <h2 htmlFor="type-DTP" className="sub-title">
                  1.3 {t("YTH sodir bo'lgan joy")}
                </h2>
                <Row gutter={[16]}>
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="date">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_region")}
                        trigger={["click", "hover"]}
                      >
                        {t("Viloyat")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="region"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        isNext={isNext}
                        height={60}
                        size="large"
                        name="region"
                        value={getIdIfObject(fields.region)}
                        onChange={handleSelect}
                        disabled={myAccount.region && !myAccount.is_superuser}
                        placeholder="Viloyatni tanlang"
                        options={getOptionsByFieldNameId(
                          regions,
                          "id",
                          myAccount.region
                        )}
                        // tabIndex={8}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="date">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_district")}
                        trigger={["click", "hover"]}
                      >
                        {t("Shahar/Tuman")}

                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="district"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        isNext={isNext}
                        height={60}
                        size="large"
                        name="district"
                        value={getIdIfObject(fields.district)}
                        onChange={handleSelect}
                        disabled={myAccount.region && myAccount.district}
                        placeholder="Shahar/tumanni tanlang"
                        options={getOptionsByFieldNameId(
                          districts,
                          "region",
                          fields.region
                        )}
                        // tabIndex={9}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="date">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_district")}
                        trigger={["click", "hover"]}
                      >
                        Ko'cha nomi
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="district"
                        className="error-message"
                        component="span"
                      />
                      <Select
                        size="large"
                        name="street_name"
                        className="streets"
                        value={fields.street_name}
                        onSelect={handleStreetSelect}
                        notFoundContent={
                          <p
                            style={{
                              textAlign: "center",
                              padding: "100px",
                            }}
                          >
                            Ko'cha nomi yuklanmoqda bu biroz vaqt oladi...
                          </p>
                        }
                        placeholder="Ko'cha nomini tanlang"
                        showSearch
                        options={streets.map((item) => ({
                          value: item.name,
                          label: item.name,
                          key: item.id || item.name,
                        }))}
                        style={{
                          width: "100%",
                        }}
                      ></Select>
                    </div>
                  </Col>
                </Row>

                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label>
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_geolocation")}
                        trigger={["click", "hover"]}
                      >
                        {t("Geo manzil")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18} style={{ display: "flex" }}>
                    <ErrorMessage
                      name="location"
                      className="error-message"
                      component="span"
                    />
                    <Input
                      isNext={isNext}
                      type="text"
                      className={`input-controller focusable ${
                        isNext ? (fields.location ? "" : "error") : ""
                      }`}
                      name="location"
                      // className="input-controller focusable"
                      value={fields.location}
                      autoComplete="off"
                      disabled
                      onChange={(val) => handleInput("location", val)}
                      placeholder={t("YTH geo manzilni kiriting")}
                    />
                    <Button
                      size="large"
                      className="ml-4 px-3 focusable flex-centered"
                      // tabIndex={}
                      onClick={() => setMapVisible(true)}
                    >
                      {t("Xaritadan tanlash")}
                      <GrLocation className="hover-color-white has-icon" />
                    </Button>
                  </Col>
                </Row>

                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_street_significance")}
                        trigger={["click", "hover"]}
                      >
                        {t("Yo'l")}
                        {/* <QuestionMark className="info-badge clickable" /> */}
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="road_list"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        height={60}
                        size="large"
                        name="road_list"
                        value={fields.road_list}
                        onChange={handleSelect}
                        placeholder="Yo'lni tanlang"
                        options={road_list}
                        // tabIndex={13}
                      />
                    </div>
                  </Col>
                </Row>

                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_street_significance")}
                        trigger={["click", "hover"]}
                      >
                        {t("Yo'l/ko'chaning ahamyati")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="street_significance"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        isNext={isNext}
                        className={`input-controller focusable ${
                          isNext
                            ? fields?.street_significance
                              ? ""
                              : "error"
                            : ""
                        }`}
                        height={60}
                        size="large"
                        name="street_significance"
                        value={getIdIfObject(fields.street_significance)}
                        onChange={handleSelect}
                        placeholder="Yo'l/ko'cha ahamayatini tanlang"
                        options={street_significances}
                        // tabIndex={13}
                      />
                    </div>
                  </Col>
                </Row>

                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_distance_from")}
                        trigger={["click", "hover"]}
                      >
                        {t("Yo'lning qaysi KMda")}{" "}
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="distance_from"
                        className="error-message"
                        component="span"
                      />
                      <InputMask
                        name="distance_from"
                        className="input-controller focusable"
                        value={fields.distance_from}
                        mask="99999"
                        max="99999"
                        min="0"
                        maskChar=""
                        inputMode="numeric"
                        onChange={(event) => {
                          const { value } = event.target;
                          handleDatepicker("distance_from", value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </article>

              <Divider style={{ margin: "40px 0" }} />
              {/* ------- 1.4 ------- */}

              <article className="px-4">
                <h2 htmlFor="type-DTP" className="sub-title">
                  1.4 {t("Yo'l sharoitlari")}
                </h2>
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("road_surface")}
                        trigger={["click", "hover"]}
                      >
                        {t("Yo'lning qoplama turi")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="road_surface"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        isNext={isNext}
                        className={`input-controller focusable ${
                          isNext ? (fields?.road_surface ? "" : "error") : ""
                        }`}
                        height={60}
                        size="large"
                        name="road_surface"
                        value={getIdIfObject(fields.road_surface)}
                        onChange={handleSelect}
                        placeholder="Yo'lning qoplama turi"
                        options={road_surfaces}
                        // tabIndex={15}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("road_condition")}
                        trigger={["click", "hover"]}
                      >
                        {t("Qatnov qismining xolati")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="road_condition"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        isNext={isNext}
                        className={`input-controller focusable ${
                          isNext ? (fields?.road_condition ? "" : "error") : ""
                        }`}
                        height={60}
                        size="large"
                        name="road_condition"
                        value={getIdIfObject(fields.road_condition)}
                        onChange={handleSelect}
                        placeholder="Qatnov qismining xolati"
                        options={road_conditions}
                        // tabIndex={16}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("road_weather_condition")}
                        trigger={["click", "hover"]}
                      >
                        {t("Ob-havo sharoiti")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="weather_condition"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        isNext={isNext}
                        className={`input-controller focusable ${
                          isNext
                            ? fields?.weather_condition
                              ? ""
                              : "error"
                            : ""
                        }`}
                        height={60}
                        size="large"
                        name="weather_condition"
                        value={getIdIfObject(fields.weather_condition)}
                        onChange={handleSelect}
                        placeholder="Yo'lning qoplama turi"
                        options={weather_conditions}
                        // tabIndex={17}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_residence_type")}
                        trigger={["click", "hover"]}
                      >
                        {t("Aholi yashash punkti")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="residence_type"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        isNext={isNext}
                        className={`input-controller focusable ${
                          isNext ? (fields?.residence_type ? "" : "error") : ""
                        }`}
                        height={60}
                        size="large"
                        name="residence_type"
                        value={getIdIfObject(fields.residence_type)}
                        onChange={handleSelect}
                        placeholder="Aholi yashash punktini tanlang"
                        options={getOptionsByFieldNameId(residence_types)}
                      />
                    </div>
                  </Col>
                </Row>
                {fields.part_of_day === "night" && (
                  <Row gutter={[16]} className="mt-4">
                    <Col span={6} className="label-wrapper">
                      <label htmlFor="">
                        <Tooltip
                          placement="right"
                          title={getDescription("road_illumination")}
                          trigger={["click", "hover"]}
                        >
                          {t("Yoritilganlik")}
                          <sup
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </sup>
                          <QuestionMark className="info-badge clickable" />
                        </Tooltip>
                      </label>
                    </Col>
                    <Col span={18}>
                      <div className="input-wrapper">
                        <ErrorMessage
                          name="illumination"
                          className="error-message"
                          component="span"
                        />
                        <FormSelect
                          isNext={isNext}
                          className={`input-controller focusable ${
                            isNext ? (fields?.illumination ? "" : "error") : ""
                          }`}
                          height={60}
                          size="large"
                          name="illumination"
                          value={getIdIfObject(fields.illumination)}
                          onChange={handleSelect}
                          placeholder="Yoritilganlik"
                          options={
                            fields.part_of_day === "night" ? illuminations : []
                          }
                          // tabIndex={18}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("road_part")}
                        trigger={["click", "hover"]}
                      >
                        {t("Yo'lning qismi")}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="road_part"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        isNext={isNext}
                        className={`input-controller focusable ${
                          isNext ? (fields?.road_part ? "" : "error") : ""
                        }`}
                        height={60}
                        size="large"
                        name="road_part"
                        value={getIdIfObject(fields.road_part)}
                        onChange={handleSelect}
                        placeholder="Yo'lning qismi"
                        options={road_parts}
                        // tabIndex={19}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("road_accident_causals")}
                        trigger={["click", "hover"]}
                      >
                        {t("YTH keltirib chiqaruvchi holatlar")}{" "}
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="accident_causal"
                        className="error-message"
                        component="span"
                      />
                      <FormSelect
                        isNext={isNext}
                        className={`input-controller focusable ${
                          isNext ? (fields?.accident_causal ? "" : "error") : ""
                        }`}
                        height={60}
                        size="large"
                        name="accident_causal"
                        multiple
                        value={getIdIfObject(fields.accident_causal)}
                        onChange={handleSelect}
                        placeholder="YTH keltirib chiqaruvchi holat"
                        options={accident_causals}
                        // tabIndex={20}
                      />
                    </div>
                    <p>Bu yerda subektiv va obektiv matn bo'lishi kerak</p>
                  </Col>
                </Row>
              </article>

              <Divider style={{ margin: "40px 0" }} />
              {/* ------- 1.5 ------- */}
              <article className="px-4">
                <h2 htmlFor="type-DTP" className="sub-title">
                  1.5 {t("YTH qisqacha tavsifi (bayoni)")}
                </h2>
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_description")}
                        trigger={["click", "hover"]}
                      >
                        {t("YTH qisqa bayoni")}{" "}
                        <sup
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </sup>
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={18}>
                    <div className="input-wrapper">
                      <ErrorMessage
                        name="description"
                        className="error-message"
                        comp
                        onent="span"
                      />
                      <TextArea
                        isNext={isNext}
                        className={`input-controller focusable ${
                          isNext
                            ? fields?.description?.length >= 100
                              ? ""
                              : "error"
                            : ""
                        }`}
                        maxLength={1000}
                        rows="4"
                        name="description"
                        // tabIndex={21}
                        value={fields.description}
                        placeholder={t(
                          "YTH qisqacha tavsifi (bayoni) 100 ta harfdan kam bo'lmasligi kerak."
                        )}
                        onChange={(val) => handleInput("description", val)}
                      />
                    </div>
                    <p>
                      YTH qisqacha tavsifi (bayoni) 100 ta harfdan kam
                      bo'lmasligi kerak.
                    </p>
                  </Col>
                </Row>
              </article>
              <Divider style={{ margin: "40px 0" }} />
              <article className="px-4">
                {/* ------- 1.6 ------- */}
                <h2 htmlFor="type-DTP" className="sub-title">
                  1.6 {t("YTH joyidagi video va rasmlar")}
                </h2>
                <Row gutter={[16]} className="mt-4">
                  <Col span={6} className="label-wrapper">
                    <label htmlFor="">
                      <Tooltip
                        placement="right"
                        title={getDescription("accident_photos")}
                        trigger={["click", "hover"]}
                      >
                        {t(
                          "YTH keyin avtoulovni qanday qilib to'g'ri suratga olish kerak"
                        )}{" "}
                        <QuestionMark className="info-badge clickable" />
                      </Tooltip>
                    </label>
                  </Col>
                  <Col span={12}>
                    <img
                      src={imageCar}
                      alt={t("YTH joyida transport vositasini suratga olish")}
                      width="100%"
                    />
                    <input
                      type="file"
                      multiple
                      hidden
                      name="file"
                      ref={attachmentRef}
                    />
                    <div className="my-3">
                      <UploadFile
                        isUploadTypeCard
                        files={fields.attachmentsList}
                      />
                    </div>
                  </Col>
                  <Col span={6}>
                    <ol>
                      <li>{t("Suratga 4 burchakdan oling")}</li>
                      <li>
                        {t("Suratga olish burchagi 45 darajada bo'lishi kerak")}
                      </li>
                      <li>
                        {t(
                          "Davlat raqami ko'rinadigan qilib old (chap-o'ng) va orqa (chap-o'ng) tomonlaridan suratga oling"
                        )}
                      </li>
                    </ol>
                  </Col>
                </Row>
              </article>
              <div className="text-right px-4">
                <Button
                  type="primary"
                  size="large"
                  className="primary focusable mb-3 flex-centered ml-auto"
                  // tabIndex={22}
                  disabled={isSubmitting}
                  onClick={handleStepOne}
                >
                  {t("Keyingisi")} <BsArrowRight className="has-icon" />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </S.NewCard>
    </>
  );
}
