/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useMemo, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "leaflet.heat";
import { Radio, Spin, Space } from "antd";

import configs from "../../constants";
import { indexById, t } from "../../utils";
import MapStyle from "../../styles/map";
import "../../../node_modules/react-leaflet-markercluster/dist/styles.min.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import markerIcon from "../../assets/images/pin.png";
import S from "../../styles/statistics";
import Summary from "./Summary";
import Filters from "../../components/Filters";
import Axios, { cancelToken } from "../../utils/axios";
import moment from "moment";
import Reports from "../Reports";

const tileList = {
  osmServer: {
    title: t("Standart"),
    url: `${configs.URL.REACT_APP_TECH_MAP_URL}/tile/{z}/{x}/{y}.png`,
    maxZoom: 20,
    name: "osmServer",
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  },
  googleStreets: {
    title: t("Google Street"),
    url: "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
    maxZoom: 20,
    name: "googleStreets",
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  },
  googleHybrid: {
    title: t("Gibrid"),
    url: "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
    maxZoom: 20,
    name: "googleHybrid",
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  },
};

const center = {
  lat: 41.2995,
  lng: 69.2401,
};

function HeatMap({ location }) {
  const map = useMap();
  useEffect(() => {
    const layer = L.heatLayer(location, {
      radius: 15,
      max: 0.5,
      maxZoom: 18,
      minOpacity: 0.7,
      gradient: {
        0.4: "blue",
        0.5: "skyblue",
        0.6: "green",
        0.8: "orange",
        1: "red",
      },
    }).addTo(map);
    return () => layer.remove();
  }, [location]);

  return <div></div>;
}

export default (props) => {
  const [mapType, setMapType] = useState("cluster_map");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const defaultListType =
    new URLSearchParams(window.location.search).get("list_type") || "map";
  const [listType, setListType] = useState(defaultListType);
  const { accident_types } = useSelector((state) => state.handbooks || {});
  const [zoomPercentage, setZoomPercentage] = useState(0);

  useEffect(() => {
    console.log(zoomPercentage);
    if (65 < zoomPercentage) {
      setMapType("cluster_map");
    } else {
      setMapType("heat_map");
    }
  }, [zoomPercentage]);

  const calculateZoomPercentage = (currentZoom, maxZoom) => {
    const percentage = Math.round((currentZoom / maxZoom) * 100);
    setZoomPercentage(percentage);
  };

  const ZoomEventHandler = () => {
    const map = useMap();
    map.on("zoomend", () => {
      calculateZoomPercentage(map.getZoom(), map.getMaxZoom());
    });

    return null; // This component doesn't render anything, it's just for event handling
  };
  const { lang, region, district } = useSelector(
    (state) => state.auth.user || {}
  );
  const source = cancelToken();
  const history = useHistory();
  const filters = useSelector((state) => state.statisticsFilter || {});

  const filterParams = useMemo(() => {
    let data = "";
    for (let x in filters) {
      if (Array.isArray(filters[x]) && filters[x].length) {
        data += `&${x}=${filters[x].join(",")}`;
      } else if (!Array.isArray(filters[x]) && filters[x]) {
        data += `&${x}=${filters[x]}`;
      }
    }
    return data;
  }, [filters]);

  const fetchData = async (reset = false) => {
    try {
      setLoading(true);
      let queryParams = reset ? "" : filterParams;
      const { data } = await Axios.get(
        `/reports/heat_map/?page_size=10000000${queryParams}`,
        { cancelToken: source.token }
      );
      setData(data.results);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    return () => source.cancel("Canceling fetch reports");
  }, []);

  const accidentTypeIds = useMemo(() => {
    return indexById(accident_types);
  }, [accident_types]);

  useEffect(() => {
    L.icon({
      iconUrl: markerIcon,
      iconSize: [64, 64],
      iconAnchor: [32, 64],
    });
  }, []);

  const locations = useMemo(
    () =>
      data.reduce((acc, i) => {
        if (i.location) {
          acc.push(i.location.split(",").map((i) => +i));
        }
        return acc;
      }, []),
    [data]
  );

  const { injured, died } = useMemo(
    () =>
      data.reduce(
        (acc, item) => {
          acc.injured += item.injured;
          acc.died += item.died;
          return acc;
        },
        { died: 0, injured: 0 }
      ),
    [data]
  );

  const handleListType = (e) => {
    const type = e.target.value;
    setListType(type);
    history.push(`/statistics/?list_type=${type}`);
  };

  const markers = useMemo(() => {
    return data.map((item, index) => {
      if (item.location?.length) {
        const location = item.location?.split(",").map((item) => +item);
        const { died, injured } = item;

        const customIcon = L.icon({
          iconUrl:
            died > 0
              ? "/icon_dead.svg"
              : injured > 0
              ? "/injured.svg"
              : "/health.svg",
          iconSize: [25, 25], // Adjust the size of the icon as needed
          iconAnchor: [12, 12], // Adjust the anchor point if necessary
        });

        return (
          <Marker icon={customIcon} position={location} key={index}>
            <Popup minWidth={200} closeButton={false}>
              <div>
                <b>#{item.id}</b>
                <div>
                  {" "}
                  - {new Date(item.date_accident).toLocaleString("ru")}
                </div>
                <div> - {item.location}</div>
                <div className="text-capitalize">
                  {" "}
                  - {accidentTypeIds[item.accident_type]?.[`name_${lang}`]}
                </div>
                <div> - {item.street_name}</div>
                <div>
                  {" "}
                  - {t("Xalok bo'lganlar")} {item.died ?? 0}
                </div>
                <div>
                  {" "}
                  - {t("Jarohatlanganlar")} {item.injured ?? 0}
                </div>
                <Link to={`/cards/${item.id}`}>{t("Ko'rish")}</Link>
              </div>
            </Popup>
          </Marker>
        );
      }
    });
  }, [data]);

  return (
    <S.Main>
      <div className="type-button text-center">
        <Radio.Group size="large" value={listType} onChange={handleListType}>
          <Radio.Button value="map">{t("Xarita")}</Radio.Button>
          <Radio.Button value="reports">{t("Hisobotlar")}</Radio.Button>
        </Radio.Group>
      </div>

      {listType === "reports" ? (
        <Reports params={filterParams} />
      ) : (
        <div className="d-flex page-wrapper mt-5">
          <div className="col-left">
            <MapStyle className="mt-0">
              {loading ? (
                <div className="map-spinner">
                  <Spin size="large" />
                </div>
              ) : null}
              <MapContainer
                zoom={10}
                maxZoom={17}
                scrollWheelZoom={true}
                doubleClickZoom={false}
                center={center}
                minZoom={5}
                // style={{ width: 800, height: 800 }}
              >
                <ZoomEventHandler />
                <LayersControl position="topright" collapsed={false}>
                  {Object.keys(tileList).map((item) => {
                    return (
                      <LayersControl.BaseLayer
                        key={item}
                        checked={tileList[item].name === "googleStreets"}
                        name={tileList[item].title}
                      >
                        <TileLayer
                          url={tileList[item].url}
                          subdomains={tileList[item].subdomains}
                          maxZoom={item.maxZoom}
                          projection="EPSG:4326"
                        />
                      </LayersControl.BaseLayer>
                    );
                  })}
                </LayersControl>

                {mapType === "cluster_map" ? (
                  markers
                ) : (
                  <HeatMap location={locations} />
                )}
              </MapContainer>
            </MapStyle>
            <Space size="large">
              <div className="d-flex">
                <img
                  src="/icon_dead.svg"
                  height="25px"
                  style={{
                    marginRight: "10px",
                  }}
                  alt="dead"
                />
                {t("O'lim bilan bog'lik")}
              </div>
              <div className="d-flex">
                <img
                  src="/injured.svg"
                  height="25px"
                  style={{
                    marginRight: "10px",
                  }}
                  alt="dead"
                />
                {t("Jarohatlanish")}
              </div>
              <div className="d-flex">
                <img
                  src="/health.svg"
                  height="25px"
                  style={{
                    marginRight: "10px",
                  }}
                  alt="dead"
                />
                {t("Sog'liqqa zarar yetmagan")}
              </div>
            </Space>
            <Summary
              died={died}
              injured={injured}
              total={data.length}
              endDate={filters.date_accident__lte ?? moment()}
              startDate={
                filters.date_accident__gte ??
                moment().startOf("M").utc(true).format()
              }
            />
          </div>
          <div className="b-1 col-right b-radius p-0">
            <Filters
              filters={filters}
              loading={loading}
              fetchData={fetchData}
              statistics
              startDate={
                filters.date_accident__gte ??
                moment().startOf("M").utc(true).format()
              }
              endDate={filters.date_accident__lte ?? moment()}
            />
          </div>
        </div>
      )}
    </S.Main>
  );
};
