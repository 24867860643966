import { message } from "antd";
import Axios from "axios";
import store from "../store";
import configs from "../constants";
import { AUTH_SIGN_OUT } from "../store/actionTypes";
import { t } from "./locals";

let cancelToken = () => Axios.CancelToken.source();

const axios = Axios.create({
  baseURL: configs.URL.REACT_APP_BACKEND_API_URL,
  timeout: configs.AXIOS_TIMEOUT,
  cancelToken: cancelToken().token,
});

const axiosFallback = Axios.create({
  baseURL: configs.URL_FALLBACK.REACT_APP_BACKEND_API_URL,
  timeout: configs.AXIOS_TIMEOUT,
  cancelToken: cancelToken().token,
});

const handbookAxios = Axios.create({
  baseURL: configs.URL.REACT_APP_BACKEND_ADMIN_HANDBOOK_URL,
  timeout: configs.AXIOS_TIMEOUT,
  cancelToken: cancelToken().token,
});

function getToken(config) {
  const token =
    store.getState().auth.token ||
    (localStorage["persist:root"]
      ? JSON.parse(JSON.parse(localStorage["persist:root"]).auth).token
      : "");
  config.headers.Authorization = token ? `Token ${token}` : "";
  config.headers["Accept-Language"] = store.getState().auth?.user?.lang || "uz";
  return config;
}

axios.interceptors.request.use(
  (config) => getToken(config),
  (error) => {
    console.error(error.message);
    return Promise.reject(error);
  }
);

handbookAxios.interceptors.request.use(
  (config) => getToken(config),
  (error) => {
    console.error(error.message);
    return Promise.reject(error);
  }
);

function urlParser(baseURL, response) {
  const { url, method, headers, data } = response.config;
  return axiosFallback({
    method,
    baseURL,
    url,
    headers,
    data,
  });
}

axiosFallback.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.message === "Network Error" || error.code === "ECONNABORTED") {
      message.error(
        t("Internet bilan aloqa uzuldi. Birozdan so'ng urunib ko'ring"),
        7
      );
    } else if (error.response?.status?.toString()?.startsWith("50")) {
      message.error(t("Server bilan aloqa uzuldi."), 7);
    } else if (error.response?.status === 403) {
      message.error(t("Sizda bu amalni oshirish uchun ruhsat yo'q"), 7);
    } else if (error.response?.status === 401) {
      return store.dispatch({ type: AUTH_SIGN_OUT });
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.message === "Network Error" || error.code === "ECONNABORTED") {
      return urlParser(configs.URL_FALLBACK.REACT_APP_BACKEND_API_URL, error);
    } else if (error.response?.status?.toString()?.startsWith("50")) {
      message.error(t("Server bilan aloqa uzuldi."), 7);
    } else if (error.response?.status === 403) {
      message.error(t("Sizda bu amalni oshirish uchun ruhsat yo'q"), 7);
    } else if (error.response?.status === 401) {
      return store.dispatch({ type: AUTH_SIGN_OUT });
    }
    return Promise.reject(error);
  }
);

handbookAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.message === "Network Error" || error.code === "ECONNABORTED") {
      return urlParser(
        configs.URL_FALLBACK.REACT_APP_BACKEND_ADMIN_HANDBOOK_URL,
        error
      );
    } else if (error.response?.status?.toString()?.startsWith("50")) {
      message.error(t("Server bilan aloqa uzuldi."), 7);
    } else if (error.response?.status === 403) {
      message.error(t("Sizda bu amalni oshirish uchun ruhsat yo'q"), 7);
    } else if (error.response?.status === 401) {
      return store.dispatch({ type: AUTH_SIGN_OUT });
    }
    return Promise.reject(error);
  }
);

export { axios as default, handbookAxios, cancelToken, axios };
