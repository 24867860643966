import React, { useEffect, useState, useMemo } from "react";
import S from "../../styles/statistics";
import { getOptionsByFieldNameId, t } from "../../utils";
import { BiCheckCircle } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import actions from "../../store/cardFilters/actions";
import * as cardOptions from "../../constants/cardOptions";
import moment from "moment";

import {
  Collapse,
  Checkbox,
  Select,
  Button,
  DatePicker,
  TimePicker,
  Radio,
  Input,
} from "antd";

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];
const hourFormat = "HH:mm";

const initalValue = {
  accident_types: [],
  health_condition: [],
  region_id: null,
  district_id: null,
  accident_hour: null,
  date_accident: [
    moment(moment().startOf("year")),
    moment(moment().endOf("date")),
  ],
  accident_type_id__in: [],
  date_accident__gte: moment(moment().startOf("year")).utc(true).format(),
  date_accident__lte: moment(moment().endOf("date")).utc(true).format(),
  date_accident__time__lte: null,
  date_accident__time__gte: null,
};

export default function Filters({
  updateFilters,
  fetchData,
  loading,
  statistics = false,
}) {
  const { accident_types, regions, districts } = useSelector(
    (state) => state.handbooks || {}
  );

  const filters = useSelector((state) => state.cardFilters || initalValue);
  const dispatch = useDispatch();

  const {
    lang = "uz",
    region,
    district,
  } = useSelector((state) => state.auth.user || {});

  const statuses = [
    { label: t("To'ldirilmoqda"), value: "filling" },
    { label: t("Yopilgan"), value: "closed" },
    { label: t("O'zgartirilgan"), value: "changed" },
  ];

  const handleCollapse = (key) => {
    console.log(key);
  };

  const handleSelect = (name, value) => {
    if (name === "region_id__in") {
      dispatch(
        actions.preserveState({
          district_id__in: null,
          [name]: value,
        })
      );
    } else {
      dispatch(actions.preserveState({ [name]: value }));
      // setFilters((state) => ({ ...state, [name]: value }));
    }
  };

  const handleCheckbox = (name, value) => {
    dispatch(actions.preserveState({ [name]: value }));
    // setFilters((state) => ({ ...state, [name]: value }));
  };

  const handleDate = (name, value, str) => {
    if (name === "date") {
      if (value) {
        dispatch(
          actions.preserveState({
            date_accident: [value[0], value[1]],
            date_accident__gte: value[0].startOf("day").utc(true).format(),
            date_accident__lte: value[1].endOf("day").utc(true).format(),
          })
        );
      } else {
        dispatch(
          actions.preserveState({
            date_accident: [null, null],
            date_accident__gte: null,
            date_accident__lte: null,
          })
        );
      }
    } else {
      if (value) {
        dispatch(
          actions.preserveState({
            accident_hour: [
              value[0].format(hourFormat),
              value[0].format(hourFormat),
            ],
            date_accident__time__gte: value[0].format("HH:mm:ss"),
            date_accident__time__lte: value[1].format("HH:mm:ss"),
          })
        );
      } else {
        dispatch(
          actions.preserveState({
            accident_hour: [null, null],
            date_accident__time__gte: null,
            date_accident__time__lte: null,
          })
        );
      }
    }
  };

  const options = useMemo(
    () => ({
      accident_types: accident_types.map((i) => ({
        label: i[`name_${lang}`],
        value: i.id,
      })),
      location_isempty: {
        label: "Lokatsiya mavjud bo'lmaganlari",
        value: "true",
      },
      healthConditions: cardOptions.typeOfHealthConditions.map((i) => ({
        label: i[`name_${lang}`],
        value: i.id,
      })),
      regions: regions,
      districts: getOptionsByFieldNameId(
        districts,
        "region",
        filters.region_id__in || region
      ),
    }),
    [filters, accident_types, regions, region, districts, lang]
  );

  const handleReset = () => {
    const page = new URLSearchParams(window.location.search).get("page") || 1;
    const size =
      new URLSearchParams(window.location.search).get("page_size") || 10;
    dispatch(actions.resetState(filters));
    fetchData(page, size, true);
    dispatch(actions.resetState());
  };

  const date_accident =
    filters.date_accident?.[0] && filters.date_accident?.[1]
      ? [moment(filters.date_accident?.[0]), moment(filters.date_accident?.[1])]
      : [null, null];

  return (
    <S.Filters className="">
      <div>
        <h3 style={{ fontWeight: "500" }}>
          {t("Avtomobil raqami bo'yicha qidirish")}
        </h3>
        <Input.Search
          size="large"
          onChange={(e) => handleSelect("plate_number", e.currentTarget.value)}
        />
      </div>
      <Collapse
        onChange={handleCollapse}
        expandIconPosition="right"
        bordered={false}
        defaultActiveKey={[
          "accident_types",
          "status",
          "health_conditions",
          "area",
          "date_accident",
          "location_isempty",
        ]}
      >
        <Panel
          style={{ borderBottom: "1px solid #e2e4e7" }}
          key="accident_types"
          header={
            <h3 style={{ marginLeft: "-9px" }}>{t("YTH turi bo'yicha")}</h3>
          }
        >
          <Checkbox.Group
            value={filters.accident_type_id__in}
            onChange={(val) => handleCheckbox("accident_type_id__in", val)}
            options={options.accident_types}
          />
        </Panel>
        <Panel
          style={{ borderBottom: "1px solid #e2e4e7" }}
          key="status"
          header={
            <h3 style={{ marginLeft: "-9px" }}>
              {t("YTH holatlari bo'yicha")}
            </h3>
          }
        >
          <Checkbox.Group
            value={filters.status__in}
            onChange={(val) => handleCheckbox("status__in", val)}
            options={statuses}
          />

          <div className="ant-checkbox-group">
            <Checkbox
              className="excluded ant-checkbox-group-item"
              checked={filters.is_deleted}
              onChange={(val) =>
                handleCheckbox("is_deleted", val.target.checked)
              }
            >
              {t("Hisobdan chiqarilgan")}
            </Checkbox>
          </div>
        </Panel>
        <Panel
          key="health_conditions"
          header={<h3>{t("Ishtirokchi holati bo'yicha")}</h3>}
        >
          <Checkbox.Group
            value={filters.health_condition}
            options={options.healthConditions}
            onChange={(val) => handleCheckbox("health_condition", val)}
          />
        </Panel>
        <Panel
          style={{ borderBottom: "1px solid #e2e4e7" }}
          key="location_isempty"
          header={<h3 style={{ marginLeft: "-6px" }}>{t("Geolokatsiya")}</h3>}
        >
          <Checkbox
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "11px",
              marginLeft: "10px",
              padding: "10px 3px",
            }}
            checked={filters.location_isempty}
            onChange={(val) => (
              handleCheckbox("location_isempty", val.target.checked),
              console.log(val + "value is here")
            )}
          >
            {t("Lokatsiya mavjud emas")}
          </Checkbox>
        </Panel>
        <Panel
          style={{ borderBottom: "1px solid #e2e4e7" }}
          key="area"
          className="px-2"
          header={
            <h3 style={{ marginLeft: "-14px" }}>{t("Hudud bo'yicha")}</h3>
          }
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={t("Viloyat")}
            optionFilterProp="children"
            value={filters.region_id__in || region}
            onChange={(val) => handleSelect("region_id__in", val)}
            size="large"
            allowClear
            disabled={region}
            filterOption={(input, option) => {
              return (
                option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              );
            }}
          >
            {options.regions.map((item) => {
              const title = item?.[`name_${lang}`];
              return (
                <Option value={item.id} label={title} key={item.id}>
                  {title}
                </Option>
              );
            })}
          </Select>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={t("Tuman")}
            optionFilterProp="children"
            className="mt-3"
            value={filters.district_id__in || district}
            onChange={(val) => handleSelect("district_id__in", val)}
            size="large"
            allowClear
            disabled={district}
            filterOption={(input, option) => {
              return (
                option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              );
            }}
          >
            {options.districts.map((item) => {
              const title = item?.[`name_${lang}`];
              return (
                <Option value={item.id} label={title} key={item.id}>
                  {title}
                </Option>
              );
            })}
          </Select>
        </Panel>
        <Panel
          style={{ borderBottom: "1px solid #e2e4e7" }}
          key="date_accident"
          header={<h3 style={{ marginLeft: "-4px" }}>{t("Sana bo'yicha")}</h3>}
        >
          <RangePicker
            value={date_accident}
            placeholder={[t("Sanadan"), t("Sanagacha")]}
            onChange={(val, str) => handleDate("date", val, str)}
            format={dateFormatList}
          />
          <TimePicker.RangePicker
            format={"hh:mm"}
            className="mt-3"
            onChange={(val) => handleDate("hour", val)}
            placeholder={[t("Soatdan"), t("Soatgacha")]}
          />
        </Panel>
      </Collapse>
      <div className="filter-wrapper">
        <Button type="dashed" onClick={handleReset} htmlType="reset">
          {t("Tozalash")}
        </Button>
        <Button loading={loading} onClick={() => fetchData()} type="primary">
          {t("Qo'llash")}
          <BiCheckCircle className="has-icon" style={{ fontSize: 20 }} />
        </Button>
      </div>
    </S.Filters>
  );
}
